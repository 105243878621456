import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL_V2;

export const createEvent = (accountId, data) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/events`, data);
};

export const fetchEventsList = (accountId, params) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/events`, { params });
};

export const fetchEventsListTiny = (accountId, params) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/events/tiny`, { params });
};

export const fetchEventsStatistics = (accountId, params) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/events/statistics`, {
    params,
  });
};

export const fetchEventsListForProfile = (profileId, params) => {
  return axios.get(`${BASE_URL}profiles/${profileId}/events`, { params });
};

export const deleteEvents = (accountId, ids = []) => {
  return axios.delete(`${BASE_URL}accounts/${accountId}/events`, {
    data: { ids: [...ids] },
  });
};

export const getSingleEvent = (accountId, eventId) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/events/${eventId}`);
};

export const getSingleEventForProfile = (profileId, eventId) => {
  return axios.get(`${BASE_URL}profiles/${profileId}/events/${eventId}`);
};

export const editEvent = (accountId, eventId, data) => {
  return axios.put(`${BASE_URL}accounts/${accountId}/events/${eventId}`, data);
};

export const createTask = (accountId, data) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/tasks`, data);
};

export const getTasksList = (accountId, params) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/tasks`, { params });
};

export const getTasksCount = (accountId, params) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/tasks/count`, { params });
};

export const getTasksStatistics = (accId, params) => {
  return axios.get(`${BASE_URL}accounts/${accId}/tasks/statistics`, { params });
};

export const getProfileTasksList = (profileId, params) => {
  return axios.get(`${BASE_URL}profiles/${profileId}/tasks`, { params });
};

export const getSingleTask = (accountId, taskId) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/tasks/${taskId}`);
};

export const editTask = (accountId, taskId, data) => {
  return axios.put(`${BASE_URL}accounts/${accountId}/tasks/${taskId}`, data);
};

export const deleteAllTask = (accountId, ids = []) => {
  return axios.delete(`${BASE_URL}accounts/${accountId}/tasks`, {
    data: { ids: [...ids] },
  });
};

export const completeTasks = (accountId, ids) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/tasks/complete`, {
    ids: [...ids],
  });
};

export const printEvent = (accountId, eventId) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/events/${eventId}/print`);
};

export const completeEvents = (accountId, ids) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/events/complete`, {
    ids,
  });
};
