import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const BASE_URL_V2 = process.env.REACT_APP_API_URL_V2;

export function getProfile(id) {
  return axios.get(`${BASE_URL}profile/${id}`);
}

export function getBoard(id) {
  return axios.get(`${BASE_URL}profile/${id}/score-board`);
}

export function updateProfile(id, data) {
  return axios.post(`${BASE_URL}profile/${id}`, data);
}

export function createProfile(data, disable_validation) {
  if (disable_validation) {
    return axios.post(`${BASE_URL}profile?disable_validation=1`, data);
  }
  return axios.post(`${BASE_URL}profile`, data);
}

export function getProfileMetric(id) {
  return axios.get(`${BASE_URL}profile/${id}/metrics`);
}

export function updateProfileMetric(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/metrics`, data);
}

export function attachProfile(data) {
  return axios.post(`${BASE_URL}profile/attach`, data);
}

export function updateProfileMetricAll(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/metrics/all`, data);
}

export function getParQ() {
  return axios.get(`${BASE_URL}par-questions`);
}

export function editParQ(data) {
  return axios.post(`${BASE_URL}par-questions`, data);
}

export function getProfileList(search = "", without_mapped = 0) {
  return axios.get(
    `${BASE_URL}profiles/all?search=${search}&without_mapped=${without_mapped}`
  );
}

export const getProfilesV2 = (params) => {
  return axios.get(`${BASE_URL}profiles`, { params });
};

export function getGoal(id) {
  return axios.get(`${BASE_URL}profile/${id}/goals`);
}

export function createGoal(id, metric) {
  return axios.get(`${BASE_URL}profile/${id}/goals/create?metric=${metric}`);
}

export function updateGoals(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/goals/all`, data);
}

export function getImg(id, is_progress = null) {
  const params = {
    perPage: 100,
    page: 1,
    media_type: "images",
  };

  if (is_progress !== null) {
    params.is_progress = +is_progress;
  }

  return axios.get(`${BASE_URL}profile/${id}/gallery`, { params: params });
}

export function addImg(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/gallery`, data);
}

export function deleteImg(id, photoId) {
  return axios.delete(`${BASE_URL}profile/${id}/gallery/${photoId}`);
}

export function updateImg(id, photoId, data) {
  return axios.patch(`${BASE_URL}profile/${id}/gallery/${photoId}`, data);
}

export function getCheckList(id) {
  return axios.get(`${BASE_URL}profile/${id}/checklist`);
}

export function getHighPriorityMetrics(id) {
  return axios.get(`${BASE_URL}profile/${id}/high-priority-metrics`);
}

export function getCircumferences(id) {
  return axios.get(`${BASE_URL}profile/${id}/circumferences`);
}

export function getGraphData(id, data) {
  return axios.get(
    `${BASE_URL}profile/${id}/graph-data?start_date=${data.start}&end_date=${data.end}&plot_type=${data.type}`
  );
  // return axios.get(`${BASE_URL}profile/20/graph-data?start_date=2020-01-01&end_date=2020-04-03&plot_type=body_girth`)
}

export function getActiveMetrics(id, type) {
  return axios.get(
    `${BASE_URL}profile/${id}/active-metrics?${type ? "type=" + type : ""}`
  );
}

export function getRecentMetric(id, type) {
  return axios.get(
    `${BASE_URL}profile/${id}/recent-metrics?${type ? "metric=" + type : ""}`
  );
}
export function getProgramList(id) {
  return axios.get(`${BASE_URL}profile/${id}/programs/list`);
}

export function getAssessmentList(id) {
  return axios.get(`${BASE_URL}profile/${id}/assessments`);
}

export function updateAssessmentTitle(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/assessments`, data);
}

export function getProgram(id, category) {
  return axios.get(`${BASE_URL}profile/${id}/programs?category=${category}`);
}

export function getTestingReport(id, assessment) {
  return axios.get(`${BASE_URL}profile/${id}/report/${assessment}`);
}

export function getTestingCategories(id) {
  return axios.get(`${BASE_URL}profile/${id}/testing-categories`);
}

export function getTestingFields(id, data) {
  return axios.get(
    `${BASE_URL}profile/${id}/testings/create?assessment=${data.assessment}`
  );
}

export function getTestingRecent(id, par) {
  return axios.get(
    `${BASE_URL}profile/${id}/testings/show?id=${par.id}&metric=${par.metric}`
  );
}

export function getTestingList(id, data) {
  return axios.get(
    `${BASE_URL}profile/${id}/testings?category=${data.category}` +
      `&page=${data.page || "1"}` +
      `&perPage=${data.perPage || ""}` +
      `&sort=${data.sort || ""}` +
      `&sortBy=${data.sortBy || ""}` +
      `${data.search ? "&search=" + data.search : ""}` +
      `${data.subcategory ? "&subcategory=" + data.subcategory : ""}`
  );
}

export function addTesting(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/testings`, data);
}

export function updateMetricTesting(id, testingId, data) {
  return axios.put(`${BASE_URL}profile/${id}/testings/${testingId}`, data);
}

export function updateMetricGoal(id, testingId, data) {
  return axios.put(`${BASE_URL}profile/${id}/goals/${testingId}`, data);
}

export function updateTesting(id, category, data) {
  return axios.post(`${BASE_URL}profile/${id}/testings/${category}`, data);
}
export function deleteTesting(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/testings/destroy`, data);
}

export function fieldTesting(id, metrics) {
  return axios.get(
    `${BASE_URL}profile/${id}/testings/create?metric=${metrics}`
  );
}

export function getTestingById(id, testingId, category, subcategory) {
  return axios.get(
    `${BASE_URL}profile/${id}/testings/show?id=${testingId}&category=${category}&subcategory=${subcategory}`
  );
}

export function fieldTestingByCategory(id, category, subcategory = "") {
  return axios.get(
    `${BASE_URL}profile/${id}/testings/create?category=${category}&subcategory=${subcategory}`
  );
}

export function getGoalsList(id, data) {
  return axios.get(
    `${BASE_URL}profile/${id}/goals/list?category=${data.category}` +
      `&page=${data.page || "1"}` +
      `&perPage=${data.perPage || ""}` +
      `&sort=${data.sort || ""}` +
      `&sortBy=${data.sortBy || ""}` +
      `${data.search ? "&search=" + data.search : ""}` +
      `${data.subcategory ? "&subcategory=" + data.subcategory : ""}`
  );
}

export function fieldGoalsByCategory(id, category, subcategory = "") {
  return axios.get(
    `${BASE_URL}profile/${id}/goals/create?category=${category}&subcategory=${subcategory}`
  );
}
export function getGoalsById(id, goalId, category, subcategory) {
  return axios.get(
    `${BASE_URL}profile/${id}/goals/show?id=${goalId}&category=${category}&subcategory=${subcategory}`
  );
}
export function updateGoalsByCategory(id, category, data) {
  return axios.post(`${BASE_URL}profile/${id}/goals/${category}`, data);
}
export function deleteGoals(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/goals/destroy`, data);
}

export function getProtocol(metric) {
  return axios.get(`${BASE_URL}profile/protocol?metric=${metric}`);
}

export function getReport(id, sort, sortType) {
  return axios.get(`${BASE_URL}profile/${id}/report/list`, {
    params: { sort: sort, sortType: sortType },
  });
}

export function getReportByName(id, type) {
  return axios.get(`${BASE_URL}profile/${id}/report/?name=${type}`);
}

export function sendMailAccess(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/access`, data);
}

export function saveProfileNote(id, data) {
  return axios.post(`${BASE_URL}profile/${id}/notepad`, data);
}

export const deleteWorkoutsFromProfile = (profileId, ids) => {
  return axios.delete(`${BASE_URL}profile/${profileId}/programs`, {
    data: { ids },
  });
};

export function getProfileValid(id) {
  return axios.post(`${BASE_URL}profile/${id}/check`);
}

export const getProfileTasksCount = (profileId, params) => {
  return axios
    .get(`${BASE_URL_V2}profiles/${profileId}/tasks/count`, { params })
    .then((data) => data.data);
};
