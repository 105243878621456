import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import InputMask from "react-input-mask";
import ReactDOM from "react-dom";
import { CalendarIcon } from "../../assets";

const validate = (value, future, format, past, clear, required) => {
  if (clear && value === "  /  /    ") {
    return "";
  } else if (
    `${moment(value, format).toDate()}` === "Invalid Date" &&
    required
  ) {
    return "Date is invalid";
  } else if (moment(value, format).toDate() > new Date() && future === false) {
    return "The date will not be later";
  } else if (moment(value, format).toDate() < new Date() && past === true) {
    return "The date will not be earlier";
  } else {
    return "";
  }
};

export default ({
  required = true,
  value,
  onChange,
  onSelect,
  onBlur = () => {},
  label,
  position,
  disabled,
  format = "YYYY-MM-DD",
  future = false,
  past = false,
  style,
  clear,
  error = Boolean(validate(value, future, format, past, clear, required)),
  helperText = validate(value, future, format, past, clear, required),
  onFocus,
  touchPicker = false,
  width,
  withIcon = false,
  useModalPortal = false,
  isValidationDisabled = false,
}) => {
  const ref = useRef();
  const wrap = useRef();
  const [open, setOpen] = useState(false);
  const [valueC, setValueC] = useState([]);
  const [active, setActive] = useState("day");
  const [touch, setTouch] = useState(true);
  const [portalStyles, setPortalStyles] = useState({});

  useEffect(() => {
    setTouch(touchPicker);
  }, [touchPicker]);

  useEffect(() => {
    getDate();
  }, [value]);

  useEffect(() => {
    if (open) {
      if (value && value !== "  /  /    ") {
        setValueC(
          moment(value, format)
            .format("DD-MM-YYYY")
            .split("-")
        );
      } else {
        setValueC(
          moment()
            .format("DD-MM-YYYY")
            .split("-")
        );
      }
    }
  }, [open]);

  const handleOpenPortal = () => {
    if (wrap.current) {
      const { x, y } = wrap.current.getBoundingClientRect();
      const isDesktop = window.innerWidth > 768;
      setPortalStyles({
        position: "absolute",
        top: `${y + window.scrollY + 30}px`,
        left: isDesktop ? `${x}px` : `${window.innerWidth / 4}px`,
      });
    }
    setOpen(true);
  };

  const getDate = () => {
    if (
      `${moment(value, format).toDate()}` !== "Invalid Date" &&
      !!value &&
      value.indexOf(" ") === -1
    ) {
      if (format === "YYYY-MM-DD") {
        const temp = value.split("-");
        setValueC([temp[2], temp[1], temp[0]]);
      } else if (format === "MM/DD/YYYY") {
        const temp = value.split("/");
        setValueC([temp[1], temp[0], temp[2]]);
      }
    } else {
      setValueC(
        moment()
          .format("DD-MM-YYYY")
          .split("-")
      );
    }
  };

  const setDate = (valueF, index, change) => {
    let temp = [...valueC];
    if (
      index === 1 &&
      !!change === false &&
      parseInt(temp[index]) === 12 &&
      parseInt(valueF) === 1
    ) {
      temp[index] = valueF;
      temp[2] = `${parseInt(temp[2]) + 1}`;
    } else if (
      index === 1 &&
      !!change === false &&
      parseInt(temp[index]) === 1 &&
      parseInt(valueF) === 12
    ) {
      temp[index] = valueF;
      temp[2] = `${parseInt(temp[2]) - 1}`;
    } else {
      temp[index] = valueF;
    }
    if (change) {
      onChange(moment(temp.join("-"), "DD-MM-YYYY").format(format));
      if (onSelect) {
        onSelect(moment(temp.join("-"), "DD-MM-YYYY").format(format));
      }
    } else {
      setValueC(temp);
    }

    setTouch(true);
    index === 0 && setOpen(false);
  };

  const firstDay = () => {
    const day = moment(
      `${valueC[2]}-${valueC[1]}-01`,
      "YYYY-MM-DD"
    ).isoWeekday();
    const zone = moment("1980-01-01", "YYYY-MM-DD").isoWeekday();
    return !!day ? (day === 7 ? 0 : day) + (zone === 1 ? 1 : 0) : 0;
  };

  const dayInMonth = () => {
    const day = moment(
      `${valueC[2]}-${valueC[1]}-01`,
      "YYYY-MM-DD"
    ).daysInMonth();
    return day || 31;
  };

  const DayHeader = () => (
    <>
      <div
        className="modal-d__header-nav hover-grey"
        onClick={() =>
          setDate(
            valueC[1] === "01"
              ? "12"
              : `${
                  parseInt(valueC[1]) - 1 < 10
                    ? `0${parseInt(valueC[1]) - 1}`
                    : `${parseInt(valueC[1]) - 1}`
                }`,
            1
          )
        }
      >
        <ArrowBackIosIcon />
      </div>

      <div
        className="modal-d__header-nav-full hover-grey"
        onClick={() => setActive("month")}
      >
        {`${moment(
          `${valueC[0] === "00" ? "01" : valueC[0]}-${valueC[1]}-${valueC[2]}`,
          "DD-MM-YYYY"
        ).format("MMMM YYYY")}` === "Invalid date"
          ? ""
          : moment(
              `${valueC[0] === "00" ? "01" : valueC[0]}-${valueC[1]}-${
                valueC[2]
              }`,
              "DD-MM-YYYY"
            ).format("MMMM YYYY")}
      </div>

      <div
        className="modal-d__header-nav hover-grey"
        onClick={() =>
          setDate(
            valueC[1] === "12"
              ? "01"
              : `${
                  parseInt(valueC[1]) + 1 < 10
                    ? `0${parseInt(valueC[1]) + 1}`
                    : `${parseInt(valueC[1]) + 1}`
                }`,
            1
          )
        }
      >
        <ArrowForwardIosIcon />
      </div>
    </>
  );

  const DayBody = () => (
    <>
      <div className="modal-d__month-title">Su</div>
      <div className="modal-d__month-title">Mo</div>
      <div className="modal-d__month-title">Tu</div>
      <div className="modal-d__month-title">We</div>
      <div className="modal-d__month-title">Th</div>
      <div className="modal-d__month-title">Fr</div>
      <div className="modal-d__month-title">Sa</div>
      {[...new Array(firstDay())].map((item, index) => {
        return <div key={index + "clear"} className="modal-d__month-day" />;
      })}
      {[...new Array(dayInMonth())].map((item, index) => {
        return (
          <div
            key={index}
            className={`modal-d__month-day${
              value ===
              `${moment(
                moment(
                  `${index + 1 < 10 ? `0${index + 1}` : index + 1}-${
                    valueC[1]
                  }-${valueC[2]}`,
                  "DD-MM-YYYY"
                ).toDate()
              ).format(format)}`
                ? " active"
                : ""
            }${
              !isValidationDisabled
                ? (moment(
                    `${index + 1 < 10 ? `0${index + 1}` : index + 1}-${
                      valueC[1]
                    }-${valueC[2]}`,
                    "DD-MM-YYYY"
                  ).toDate() <= new Date() ||
                    future) &&
                  !(
                    moment(
                      `${index + 1 < 10 ? `0${index + 1}` : index + 1}-${
                        valueC[1]
                      }-${valueC[2]}`,
                      "DD-MM-YYYY"
                    ).toDate() < new Date() && past
                  )
                  ? " hover-grey"
                  : " disabled"
                : " hover-grey"
            }`}
            onClick={() => {
              if (isValidationDisabled) {
                setDate(
                  `${index + 1 < 10 ? `0${index + 1}` : index + 1}`,
                  0,
                  true
                );
              } else {
                if (
                  (moment(
                    `${index + 1 < 10 ? `0${index + 1}` : index + 1}-${
                      valueC[1]
                    }-${valueC[2]}`,
                    "DD-MM-YYYY"
                  ).toDate() <= new Date() ||
                    future) &&
                  !(
                    moment(
                      `${index + 1 < 10 ? `0${index + 1}` : index + 1}-${
                        valueC[1]
                      }-${valueC[2]}`,
                      "DD-MM-YYYY"
                    ).toDate() < new Date() && past
                  )
                ) {
                  setDate(
                    `${index + 1 < 10 ? `0${index + 1}` : index + 1}`,
                    0,
                    true
                  );
                }
              }
            }}
            style={{
              background:
                moment().format("DD-MM-YYYY") ===
                `${index + 1 < 10 ? `0${index + 1}` : index + 1}-${valueC[1]}-${
                  valueC[2]
                }`
                  ? "#d8ebff"
                  : "",
            }}
          >
            {index + 1}
          </div>
        );
      })}
    </>
  );

  const MonthHeader = () => (
    <>
      <div
        className="modal-d__header-nav hover-grey"
        onClick={() => setDate(parseInt(valueC[2]) - 1, 2)}
      >
        <ArrowBackIosIcon />
      </div>

      <div
        className="modal-d__header-nav-full hover-grey"
        onClick={() => setActive("year")}
      >
        {`${moment(
          `${valueC[0]}-${valueC[1]}-${valueC[2]}`,
          "DD-MM-YYYY"
        ).format("YYYY")}` === "Invalid date"
          ? ""
          : moment(
              `${valueC[0]}-${valueC[1]}-${valueC[2]}`,
              "DD-MM-YYYY"
            ).format("YYYY")}
      </div>

      <div
        className="modal-d__header-nav hover-grey"
        onClick={() => setDate(parseInt(valueC[2]) + 1, 2)}
      >
        <ArrowForwardIosIcon />
      </div>
    </>
  );

  const MonthBody = () => (
    <>
      {[...new Array(12)].map((item, index) => {
        return (
          <div
            key={index}
            className={`modal-d__year-month${
              value ===
              `${moment(
                moment(
                  `${valueC[0]}-${
                    index + 1 < 10 ? `0${index + 1}` : `${index + 1}`
                  }-${valueC[2]}`,
                  "DD-MM-YYYY"
                ).toDate()
              ).format(format)}`
                ? " active"
                : " hover-grey"
            }`}
            onClick={() => {
              setDate(index + 1 < 10 ? `0${index + 1}` : `${index + 1}`, 1);
              setActive("day");
            }}
          >
            {moment(`01-${index + 1}-${valueC[2]}`, "DD-MM-YYYY").format("MMM")}
          </div>
        );
      })}
    </>
  );

  const YearHeader = () => (
    <>
      <div
        className="modal-d__header-nav hover-grey"
        onClick={() => setDate(`${parseInt(valueC[2]) - 16}`, 2)}
      >
        <ArrowBackIosIcon />
      </div>

      <div className="modal-d__header-nav-full hover-grey">
        {`${parseInt(valueC[2])} - ${parseInt(valueC[2]) + 15}`}
      </div>

      <div
        className="modal-d__header-nav hover-grey"
        onClick={() => setDate(`${parseInt(valueC[2]) + 16}`, 2)}
      >
        <ArrowForwardIosIcon />
      </div>
    </>
  );

  const YearBody = () => (
    <>
      {[...new Array(16)].map((item, index) => {
        return (
          <div
            key={index}
            className={`modal-d__year-month${
              value ===
              `${moment(
                moment(
                  `${valueC[0]}-${valueC[1]}-${parseInt(valueC[2]) + index}`,
                  "DD-MM-YYYY"
                ).toDate()
              ).format(format)}`
                ? " active"
                : " hover-grey"
            }`}
            onClick={() => {
              setDate(parseInt(valueC[2]) + index, 2);
              setActive("month");
            }}
          >
            {`${parseInt(valueC[2]) + index}`}
          </div>
        );
      })}
    </>
  );

  const ModalContainer = () => {
    return (
      <Modal
        className={["modal-d ", position === "top" ? " top" : " bottom"]}
        style={portalStyles}
      >
        <div className="modal-d__header">
          {active === "day" && <DayHeader />}
          {active === "month" && <MonthHeader />}
          {active === "year" && <YearHeader />}
        </div>
        <div className="modal-d__month">
          {active === "day" && <DayBody />}
          {active === "month" && <MonthBody />}
          {active === "year" && <YearBody />}
        </div>
      </Modal>
    );
  };

  const ModalPortal = () => {
    return ReactDOM.createPortal(
      <ModalContainer />,
      document.getElementById("program-datepicker-portal")
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const portalModal = document.getElementById("program-datepicker-portal");
      if (useModalPortal) {
        if (portalModal && !portalModal.contains(event.target)) {
          setOpen(false);
        }
      } else if (wrap.current && !wrap.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrap]);

  return (
    <Picker ref={wrap}>
      <div
        className={`picker ${disabled ? "picker-disabled" : ""}`}
        style={{
          backgroundColor: `${disabled ? "white" : "white"}`,
          border: `${
            touch && error
              ? "1px solid #fd397a"
              : open
              ? "2px solid #5d78ff"
              : "1px solid rgba(0, 0, 0, 0.23)"
          }`,
          width: width,
        }}
      >
        {!!label ? (
          <div
            className="picker__label"
            style={{
              color: `${
                touch && error
                  ? "#fd397a"
                  : open
                  ? "#5d78ff"
                  : "rgba(0, 0, 0, 0.54)"
              }`,
              top: !open && !value ? 18 : 0,
              fontSize: !open && !value ? 18 : 13,
            }}
            onClick={(event) => {
              if (disabled) {
                return null;
              } else {
                setTouch(true);
                if (useModalPortal) {
                  handleOpenPortal(event);
                } else {
                  setOpen(true);
                }

                ref.current.getInputDOMNode().focus();
              }
            }}
          >
            {label}
          </div>
        ) : null}
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {withIcon && (
            <span
              className="datepicker-icon"
              onClick={(event) => {
                if (disabled) {
                  return null;
                } else {
                  setTouch(true);
                  if (useModalPortal) {
                    handleOpenPortal(event);
                  } else {
                    setOpen(true);
                  }
                  ref.current.getInputDOMNode().focus();
                }
              }}
            >
              <CalendarIcon />
            </span>
          )}
          <InputMask
            ref={ref}
            disabled={disabled}
            mask={format === "MM/DD/YYYY" ? "99/99/9999" : "9999-99-99"}
            value={value === "  /  /    " ? "" : value || ""}
            onChange={(e) => {
              if (disabled) {
                return null;
              }
              const index = e.target.value.indexOf(" ");
              if (
                index === -1 &&
                `${moment(e.target.value, format).toDate()}` === "Invalid Date"
              ) {
                // !clear && onChange('')
              } else {
                onChange(e.target.value);
              }
            }}
            onClick={() => setOpen(true)}
            onFocus={(event) => {
              if (onFocus) onFocus();
              if (disabled) return null;
              setTouch(true);
              if (useModalPortal) {
                handleOpenPortal(event);
              } else {
                setOpen(true);
              }
            }}
            onBlur={() => {
              if (onBlur) setTimeout(() => onBlur(), 100);
            }}
            maskChar=" "
            className="input-mask"
            style={{ ...style, width: "100%" }}
          />
        </div>

        {value !== "  /  /    " && (
          <div className="x-button" onClick={() => onChange("  /  /    ")}>
            &#10006;
          </div>
        )}
        {open && useModalPortal && <ModalPortal />}
        {open && !useModalPortal && <ModalContainer />}
      </div>
      {error && helperText && touch && (
        <p
          style={{
            color: "#fd397a",
            margin: "0 14px",
            fontSize: "0.75rem",
            fontWeight: "400",
            maxWidth: "150px",
          }}
        >
          {helperText}
        </p>
      )}
    </Picker>
  );
};

const Modal = styled.div`
  position: absolute;
  right: 0;
  height: 290px;
  width: 265px;
  background-color: #fff;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.42rem;
  box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
  z-index: 9999909999999;
  padding: 10px;

  &.top {
    bottom: calc(100% + 2px);
  }

  &.bottom {
    top: calc(100% + 2px);
  }

  .modal-d__header {
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-nav {
      height: 100%;
      width: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;

      &-full {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        font-weight: bolder;
        cursor: pointer;
      }
    }
  }

  .modal-d__month {
    display: flex;
    flex-wrap: wrap;

    &-title {
      height: 30px;
      display: flex;
      width: calc(100% / 7);
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }

    &-day {
      height: 35px;
      width: calc(100% / 7);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      &.active {
        background: #3699ff !important;
        color: white;
      }
    }
  }

  .modal-d__year {
    display: flex;
    flex-wrap: wrap;

    &-month {
      height: 55px;
      width: calc(100% / 4);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;
      margin-top: 5px;

      &.active {
        background: #3699ff;
        color: white;
      }
    }
  }

  .hover-grey {
    cursor: pointer;

    &:hover {
      background: #f3f6f9;
    }
  }

  .disabled {
    opacity: 0.5;
  }
`;

const Picker = styled.div`
  position: relative;

  .input-mask {
    border: none;
    outline: none;
    background: transparent;
  }

  .picker-disabled .datepicker-icon {
    cursor: unset;
  }

  .datepicker-icon {
    cursor: pointer;
    margin-top: -3px;
  }

  .picker {
    min-width: 120px;
    height: 44px;
    border-radius: 4px;
    padding: 0 14px;
    margin-top: 16px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    position: relative;

    &-icon {
      position: absolute;
      right: 10px;
    }

    &__label {
      background: white;
      position: absolute;
      left: 2px;
      padding: 3px;
      font-weight: 400;
      transform: translateY(-50%) scale(0.75);
      transition: all 0.15s ease-in;
    }
  }

  .x-button {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
`;
