import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, Button } from "react-bootstrap";
import { getProgramsTree, addToProgram } from "../../../../crud/library.crud";
import Loader from "../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";
import * as AlertState from "../../../../../app/store/ducks/auth.duck";
import catchErrorMessage from "../../../../helpers/errorCatcher";
import { ArrowDropDown } from "@material-ui/icons";

export const AddToProgramModal = ({ modal, setModal, loadData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [programs, setPrograms] = useState([]);
  const [loader, setLoader] = useState(false);
  const userId = useSelector((state) => state.auth.user.id);
  const profile_id = location.pathname.split("/")[2];
  const [tab, setTab] = useState(0);

  useEffect(() => {
    setLoader(true);
    getProgramsTree({
      creator_ids: tab === 0 ? [] : [userId],
      profile_id,
      page: 1,
      perPage: 500,
    })
      .then((res) => {
        if (!res.data?.programs) return;
        setPrograms(
          [...res.data.programs]
            .filter((elem) => elem.title !== "Unattached")
            .map((elem) => ({
              ...elem,
              workouts: elem.workouts.map((workout) => ({
                ...workout,
                disabled: workout.is_attached ? true : false,
              })),
            }))
            .map((elem) => {
              if (elem.workouts.some((item) => item.is_attached)) {
                return { ...elem, checked: true };
              } else {
                return { ...elem, checked: false };
              }
            })
        );
      })
      .finally(() => setLoader(false));
  }, [tab]);

  const handleClose = () => {
    setModal(false);
  };

  const handleSubmit = () => {
    setLoader(true);
    const data = {
      is_unattach_enabled: true,
      programs: programs
        .filter((elem) => elem.workouts?.length !== 0)
        .map((elem) => {
          let data = { ...elem };
          if (data.workouts.filter((item) => item.is_attached).length === 0) {
            delete data.workouts;
          } else {
            data.workouts = data.workouts
              .filter((item) => item.is_attached)
              .map((item) => ({ id: item.id }));
          }
          return data;
        })
        .filter((elem) => elem.hasOwnProperty("workouts")),
    };

    addToProgram(profile_id, data)
      .then(() => {
        dispatch(
          AlertState.actions.alert({
            text: "Program added successfully",
            variant: true,
          })
        );
        setModal(false);
        loadData();
      })
      .catch((err) => {
        let errText = catchErrorMessage(err);
        if (errText === "The programs field is required") {
          errText = "Select at least one workout";
        }
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  const handleCheck = (programIndex, workoutIndex) => {
    if (typeof workoutIndex === "undefined") {
      setPrograms((prev) => {
        const arr = [...prev];
        arr[programIndex].checked = !!!arr[programIndex].checked;
        return arr;
      });
    } else {
      setPrograms((prev) => {
        const arr = [...prev];
        arr[programIndex].workouts[workoutIndex].is_attached = !!!arr[
          programIndex
        ].workouts[workoutIndex].is_attached;
        return arr;
      });
    }
  };

  const handleCheckAll = (programIndex) => {
    const arr = [...programs];

    arr[programIndex].workouts = arr[programIndex].workouts.map((item) => ({
      ...item,
      is_attached: true,
    }));

    setPrograms(arr);
  };

  const handleUnCheckAll = (programIndex) => {
    const arr = [...programs];

    arr[programIndex].workouts = arr[programIndex].workouts.map((item) => ({
      ...item,
      is_attached: false,
    }));

    setPrograms(arr);
  };

  const handleCheckAllPrograms = () => {
    const newArr = programs.map((item) => ({
      ...item,
      checked: !!item.workouts.length,
    }));
    setPrograms(newArr);
  };

  const handleUnCheckAllPrograms = () => {
    const newArr = programs.map((item) => ({
      ...item,
      checked: false,
    }));
    setPrograms(newArr);
  };

  return (
    <Modal show={modal !== false} onHide={() => setModal(false)}>
      <Loader visible={loader} />

      <StyledAddConfirmModal>
        <div className="close-button" onClick={() => setModal(false)}>
          ×
        </div>
        <Modal.Title>
          <h5 className="title">Add Program</h5>
        </Modal.Title>
        <Modal.Body>
          <Tabs
            value={tab}
            onChange={(e, value) => setTab(value)}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
            style={{ marginBottom: 20 }}
          >
            <Tab label={"ALL PROGRAMS"} />
            <Tab label={"MY PROGRAMS"} />
          </Tabs>

          <div className="select__options">
          {/* <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (programs?.some((item) => item.checked)) {
                  handleUnCheckAllPrograms();
                } else {
                  handleCheckAllPrograms();
                }
              }}
            >
              {programs?.some((item) => item.checked)
                ? "Unselect All Programs"
                : "Select All Programs"}
            </p> */}
            {programs.map((program, programIndex) => {
              return (
                <div key={programIndex}>
                  <div className="select__option">
                    <Checkbox
                      checked={
                        !programs[programIndex].workouts.find(
                          (item) => !item.is_attached
                        )
                      }
                      color="secondary"
                      onChange={(e) =>
                        e.target.checked
                          ? handleCheckAll(programIndex)
                          : handleUnCheckAll(programIndex)
                      }
                    />
                    <div
                      className="pointer"
                      onClick={() => handleCheck(programIndex)}
                      style={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ margin: "0" }}>{program.title}</p>{" "}
                      <ArrowDropDown
                        className={`select__option-arrow__down ${
                          program?.checked ? "open" : ""
                        }`}
                      />
                      {/* {!!program.checked && program?.workouts?.length && (
                        <p
                          onClick={(e) => {
                            e.stopPropagation();
                            if (
                              program?.workouts.some((item) => item.is_attached)
                            ) {
                              handleUnCheckAll(programIndex);
                            } else {
                              handleCheckAll(programIndex);
                            }
                          }}
                          style={{ margin: "0" }}
                        >
                          {!program?.workouts.some((item) => item.is_attached)
                            ? "(Select all workouts)"
                            : "(Unselect all workouts)"}
                        </p>
                      )} */}
                    </div>
                  </div>

                  {program.checked && (
                    <div style={{ marginLeft: 20 }}>
                      {programs[programIndex].workouts.length > 0 ? (
                        programs[programIndex].workouts.map(
                          (workout, workoutIndex) => {
                            return (
                              <div
                                className="select__option"
                                key={workoutIndex}
                              >
                                <Checkbox
                                  color="primary"
                                  onChange={() =>
                                    handleCheck(programIndex, workoutIndex)
                                  }
                                  checked={!!workout.is_attached}
                                  disabled={!!workout.disabled}
                                />
                                <div
                                  className="pointer"
                                  onClick={() =>
                                    handleCheck(programIndex, workoutIndex)
                                  }
                                >
                                  {workout.title}
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <div style={{ marginLeft: 10 }}>
                          No assigned workouts
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} className="btn-blue">
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </StyledAddConfirmModal>
    </Modal>
  );
};

const StyledAddConfirmModal = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .modal-body {
    padding: 2.25rem;
  }

  .close-button {
    position: absolute;
    top: 9px;
    right: 20px;
    cursor: pointer;
    color: grey;
    font-size: 2.5em;
  }

  .title {
    margin: 20px 60px 0 30px;
    font-size: 1.4rem;
  }

  .select {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .select__title {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    width: 100px;
  }

  .select__arrow {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transform: rotate(90deg);
    transition: 0.2s;
  }

  .select__arrow_opened {
    transform: rotate(-90deg);
    transition: 0.2s;
  }

  .select__options {
    margin-left: -10px;
    max-height: 320px;
    overflow: auto;
  }

  .select__option {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .select__option-arrow__down {
    /* transform: rotate(-90deg); */

    &.open {
      transform: rotate(180deg);
    }
  }
`;
