import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Loader from "../../../../components/Loader";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { useDispatch, useSelector } from "react-redux";
import catchErrorMessage from "../../../../helpers/errorCatcher";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import SimpleDropdown from "../../../../components/SimpleDropdown";
import {
  createProgram,
  editProgram,
  fetchProgram,
} from "../../../../crud/library.crud";
import { useHistory, useLocation } from "react-router-dom";
import { DragAndDropWorkouts } from "./DragAndDropWorkouts/DragAndDropWorkouts";
import { validateFullForm } from "./validate";
import { getMetric } from "../../../../crud/info.crud";
import DropdownMultiselect from "../../../../components/DropdownMultiselectv2";
import "./create.scss";

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too short - should be at least 2 characters")
    .max(100, "Too long - should not exceed 100 characters")
    .required("Required"),
  duration: Yup.number()
    .min(0, "Duration is invalid")
    .max(999, "Duration is invalid")
    .required("Required"),
  future_days_limit: Yup.number().when("visibility", {
    is: "limited_future_days",
    then: Yup.number()
      .min(0, "Number of future days is invalid")
      .max(999, "Number of future days is invalid")
      .required("Required"),
    otherwise: Yup.number().nullable(),
  }),
  access: Yup.string().required("Required"),
  visibility: Yup.string().required("Required"),
  description: Yup.string()
    .min(5, "Too short - should be at least 5 characters")
    .max(300, "Too long - should not exceed 300 characters")
    .required("Required"),
  primary_metrics: Yup.array()
    .min(1, "Required")
    .required("Required"),
});

export const CreateEditProgram = ({ match }) => {
  const dispatch = useDispatch();
  const [metrics, setMetrics] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      duration: "",
      future_days_limit: null,
      access: "myself",
      visibility: "all",
      description: "",
      primary_metrics: [],
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => submitForm(values),
  });
  const [workouts, setWorkouts] = useState([
    {
      id: "drag-" + Math.random(),
      title: "",
    },
  ]);
  const subscription = useSelector(
    (state) => state?.user_info?.subscription?.plan?.name
  );
  const programId = match.params.id || location.state?.programId;
  const isCloneForLibrary = location.state?.isCloneForLibrary || false;

  useEffect(() => {
    getMetric().then((res) => {
      let arr = res.data.data
        .filter((i) => i.pseudonym !== "progress_photo")
        .map((elem) => ({ ...elem, title: elem.metric }));
      setMetrics(arr);
    });
  }, []);

  useEffect(() => {
    if (programId) {
      setLoader(true);
      fetchProgram(programId)
        .then((res) => {
          formik.setValues({
            title: isCloneForLibrary
              ? "Copy of " + res.data.data.title
              : res.data.data.title,
            duration: res.data.data.duration,
            access: res.data.data.access,
            visibility: res.data.data.visibility,
            future_days_limit: res.data.data.future_days_limit,
            description: res.data.data.description,
            primary_metrics: res.data.data.primary_metrics.map((elem) => ({
              ...elem,
              title: elem.metric,
              id: elem.metric_id,
            })),
          });
          setWorkouts(
            res.data.data.workouts.map((elem) => ({
              ...elem,
              id: `drag-${elem.id}`,
              workoutId: elem.id,
            }))
          );
        })
        .finally(() => setLoader(false));
    }
  }, [match]);

  function submitForm() {
    setLoader(true);
    const values = { ...formik.values };
    values.primary_metric_ids = values.primary_metrics.map((elem) => elem.id);
    delete values.primary_metrics;

    const data = {
      ...values,
      workouts: workouts.map((elem) => +elem.workoutId),
    };

    if (match.params.id) {
      editProgram(match.params.id, data)
        .then(() => {
          setLoader(false);
          history.push("/library/programs/list");
          formik.setSubmitting(false);
          dispatch(
            AlertState.actions.alert({
              text: "Program is saved",
              variant: true,
            })
          );
        })
        .catch((err) => {
          formik.setSubmitting(false);
          setLoader(false);
          let errText = catchErrorMessage(err) || "Edit Program Error";
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        });
    } else {
      createProgram(data)
        .then(() => {
          formik.setSubmitting(false);
          setLoader(false);
          history.push("/library/programs/list");
          dispatch(
            AlertState.actions.alert({
              text: "Program is created",
              variant: true,
            })
          );
        })
        .catch((err) => {
          formik.setSubmitting(false);
          setLoader(false);
          let errText = catchErrorMessage(err) || "Create Program Error";
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        });
    }
  }

  const handleAddWorkout = () => {
    setWorkouts((prev) => {
      return [
        ...prev,
        {
          id: "drag-" + Math.random(),
          title: "",
        },
      ];
    });
  };

  return (
    <div className="create-program">
      <Loader visible={loader} />

      <div className="create-program__page-title">
        {match.params.id ? "Edit Program" : "Create Program"}
      </div>

      <form onSubmit={formik.onsubmit}>
        <div className="create-program__page-header">
          <div className="create-program__textfield">
            <TextField
              name="title"
              variant="outlined"
              type="text"
              onMouseDown={(e) => e.stopPropagation()}
              label="Program Name"
              inputProps={{ maxLength: 100 }}
              style={{ width: "100%" }}
              error={Boolean(formik.errors.title && formik.touched.title)}
              helperText={formik.touched.title && formik.errors.title}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </div>

          <div className="create-program__row-container">
            <div
              className="create-program__textfield"
              style={{ marginRight: 20 }}
            >
              <TextField
                name="duration"
                variant="outlined"
                type="number"
                label="Duration"
                inputProps={{
                  min: 0,
                  max: 999,
                }}
                error={Boolean(
                  formik.errors.duration && formik.touched.duration
                )}
                helperText={formik.touched.duration && formik.errors.duration}
                onBlur={formik.handleBlur}
                value={formik.values.duration}
                onChange={formik.handleChange}
                onKeyPress={(e) => {
                  if (
                    (+e.which !== 8 && +e.which !== 0 && +e.which < 48) ||
                    +e.which > 57
                  ) {
                    e.preventDefault();
                  }
                }}
                style={{ width: "100%" }}
              />
            </div>

            <div className="create-program__dropdown">
              <SimpleDropdown
                width={"100%"}
                name="access"
                label="Access"
                value={
                  formik.values.access === "myself"
                    ? "Myself"
                    : formik.values.access === "share"
                    ? "Shared with All Users"
                    : formik.values.access === "share_groups"
                    ? "Shared with Group"
                    : ""
                }
                onChange={(value) =>
                  formik.handleChange({
                    target: {
                      value:
                        value === "Myself"
                          ? "myself"
                          : value === "Shared with All Users"
                          ? "share"
                          : value === "Shared with Group"
                          ? "share_groups"
                          : "",
                      name: "access",
                    },
                  })
                }
                error={Boolean(formik.touched.access && formik.errors.access)}
                helperText={formik.touched.access && formik.errors.access}
                options={
                  subscription === "Enterprise"
                    ? ["Myself", "Shared with All Users", "Shared with Group"]
                    : subscription === "Gym"
                    ? ["Myself", "Shared with All Users"]
                    : []
                }
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="create-program__row-container">
            <div className="create-program__dropdown">
              <SimpleDropdown
                width={"100%"}
                name="visibility"
                label="Visibility"
                value={
                  formik.values.visibility === "all"
                    ? "Clients can see all workouts"
                    : formik.values.visibility === "limited_future_days"
                    ? "Clients can see past and future workouts (limited to next X days)"
                    : ""
                }
                onChange={(value) => {
                  // Reset future_days_limit when changing visibility
                  const visibilityValue =
                    value === "Clients can see all workouts"
                      ? "all"
                      : value ===
                        "Clients can see past and future workouts (limited to next X days)"
                      ? "limited_future_days"
                      : "";

                  formik.setValues({
                    ...formik.values,
                    visibility: visibilityValue,
                    ...(visibilityValue !== "limited_future_days" && {
                      future_days_limit: null,
                    }),
                  });
                }}
                error={Boolean(
                  formik.touched.visibility && formik.errors.visibility
                )}
                helperText={
                  formik.touched.visibility && formik.errors.visibility
                }
                options={[
                  "Clients can see all workouts",
                  "Clients can see past and future workouts (limited to next X days)",
                ]}
                onBlur={formik.handleBlur}
              />
            </div>

            {formik.values.visibility === "limited_future_days" && (
              <div
                className="create-program__textfield"
                style={{ marginLeft: 20 }}
              >
                <TextField
                  name="future_days_limit"
                  variant="outlined"
                  type="number"
                  label="Number of future days to include"
                  inputProps={{
                    min: 0,
                    max: 999,
                  }}
                  error={Boolean(
                    formik.errors.future_days_limit &&
                      formik.touched.future_days_limit
                  )}
                  helperText={
                    formik.touched.future_days_limit &&
                    formik.errors.future_days_limit
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.future_days_limit || ""}
                  onChange={formik.handleChange}
                  onKeyPress={(e) => {
                    if (
                      (+e.which !== 8 && +e.which !== 0 && +e.which < 48) ||
                      +e.which > 57
                    ) {
                      e.preventDefault();
                    }
                  }}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          </div>

          <div className="create-program__textarea">
            <TextField
              name="description"
              label="Description"
              variant="outlined"
              type="text"
              style={{ width: "100%" }}
              inputProps={{ maxLength: 300 }}
              onChange={formik.handleChange}
              value={formik.values.description}
              error={
                !!(formik.touched.description && formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              onBlur={formik.handleBlur}
              multiline
            />
          </div>

          <div
            className="create-program__dropdown"
            style={{ position: "relative", zIndex: 3 }}
          >
            <DropdownMultiselect
              style={{ width: "100%", background: "#fff" }}
              name="primary_metrics"
              label="Assign Primary Metric(s)"
              onChange={(value) =>
                formik.handleChange({
                  target: { value: value, name: "primary_metrics" },
                })
              }
              error={Boolean(
                formik.touched.primary_metrics && formik.errors.primary_metrics
              )}
              helperText={
                formik.touched.primary_metrics && formik.errors.primary_metrics
              }
              options={metrics}
              onBlur={formik.handleBlur}
              value={formik.values.primary_metrics}
              defaultValue={formik.values.primary_metrics}
              limitTags={3}
            />
          </div>
        </div>

        <DragAndDropWorkouts
          workouts={workouts}
          setWorkouts={setWorkouts}
          setLoader={setLoader}
        />

        <div className="create-workout__section-button">
          <Button
            variant="primary"
            className="btn-blue"
            onClick={handleAddWorkout}
          >
            Add Workout
          </Button>
        </div>
      </form>

      <div className="d-flex">
        <div className="create-workout__section-button">
          <Button
            variant="primary"
            className="btn-blue"
            onClick={(e) => {
              e.preventDefault();
              formik.validateForm().then((res) => {
                formik.setErrors(res);
                formik.setTouched({
                  title: true,
                  duration: true,
                  future_days_limit: true,
                  access: true,
                  visibility: true,
                  description: true,
                  primary_metrics: true,
                });

                if (validateFullForm(workouts, setWorkouts, dispatch)) {
                  formik.handleSubmit();
                }
              });
            }}
            disabled={formik.isSubmitting}
          >
            {match.params.id ? "Save Program" : "Create Program"}
          </Button>
        </div>

        <div className="create-workout__section-button">
          <Button
            variant="primary"
            onClick={() => history.push("/library/programs/list")}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
