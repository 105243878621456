import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../../components/Loader';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { FormComponent } from './FormComponent/FormComponent';
import { fetchForm } from '../../../../crud/forms';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import './createform.scss';

export const CreateForm = ({ match }) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState(null);
    const formId = match.params.id || location.state?.formId;
    const isCloneForLibrary = location.state?.isCloneForLibrary || false;


    // edit form
    useEffect(() => {
        if (formId) {
            setLoader(true);
            fetchForm(formId)
                .then(res => {
                    setData(res.data.data);
                    setLoader(false);
                })
                .catch(err => {
                    setLoader(false);
                    let errText = catchErrorMessage(err) === 'Element does not found' ? 'Form does not exist' : 'Save form error';
                    dispatch(AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    }));
                    history.push('/library/forms/list');
                })
        }
    }, [formId]);

    return (
        <div className='create-form'>
            <Loader visible={loader} />
            <div className='create-form__page-title'>{data?.name && !isCloneForLibrary ? 'Edit Form' : 'Create Form'}</div>
            <FormComponent setLoader={setLoader} data={data} formId={formId} isCloneForLibrary={isCloneForLibrary} />
        </div>
    )
};
