import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useIsMount } from '../../../../hooks/useIsMount';
import { Button } from 'react-bootstrap';
import { TextField } from "@material-ui/core";
import { Table } from './Table/Table';
import Loader from '../../../../components/Loader';
import { fetchExercises, deleteExercises, getDictionaryList } from '../../../../crud/library.crud';
import Pagination from '../../../../components/Pagination';
import ConfirmModal from '../../../../components/ConfirmationModal';
import { Tab, Tabs } from '@material-ui/core';
import { EditExerciseModal } from './EditExerciseModal/EditExerciseModal';
import MultipleSelectCheckmarks from '../../../../components/MultipleSelectCheckmarks';
import DropdownComponent from '../../../../components/DropdownComponent';
import { ViewExerciseModal } from './ViewExerciseModal/ViewExerciseModal';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import './list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { editExercise } from '../../../../crud/library.crud';

const attributes = [
    'All',
    'Body Segment',
    'Primary Muscle(s)',
    'Equipment',
    'Mechanics',
    'Force',
    'Units of Measure',
];

const columns = [
    {
        title: 'Body Segment',
        value: 'body_segment_id',
    },
    {
        title: 'Primary Muscle(s)',
        value: 'muscle_id',
    },
    {
        title: 'Equipment',
        value: 'equipment_id',
    },
    {
        title: 'Mechanics',
        value: 'mechanic_id',
    },
    {
        title: 'Force',
        value: 'force_id',
    },
]

export const ExercisesList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isFirstRender = useIsMount();
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [show, setShow] = useState(10);
    const [page, setPage] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [check, setCheck] = useState([]);
    const [filter, setFilter] = useState(attributes);
    const [deleteModal, setDeleteModal] = useState(false);
    const [sort, setSort] = useState([null, null]);
    const [tab, setTab] = useState(0);
    const [current, setCurrent] = useState(null);
    const [editModal, setEditModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [errors, setErrors] = useState(null);
    const userId = useSelector(state => state.auth.user.id);
    const [fieldsList, setFieldsList] = useState([]);
    const [column, setColumn] = useState(null);
    const [filterByValue, setFilterByValue] = useState([]);
    const permissions = useSelector(state => state.auth.user.permissions);

    const filterParameters = () => {
        const data = {};
        if (!filterByValue) return data;

        switch (column?.title) {
            case 'Body Segment':
                data.body_segment_id = filterByValue.id
                break
            case 'Primary Muscle(s)':
                data.muscle_id = filterByValue.id
                break
            case 'Equipment':
                data.equipment_id = filterByValue.id
                break
            case 'Mechanics':
                data.mechanic_id = filterByValue.id
                break
            case 'Force':
                data.force_id = filterByValue.id
                break
            default:
        }

        return data;
    }

    const loadData = () => {
        setLoader(true);
        fetchExercises({
            search,
            page: pagination,
            perPage: show,
            sort: sort[0],
            sortBy: sort[1],
            creator_ids: tab === 0 ? [] : [userId],
            ...filterParameters()
        })
            .then(res => {
                setData(res.data.exercises);
                setLastPage(res.data.last_page);
                setLoader(false);
                setPage({
                    current_page: res.data.current_page,
                    last_page: res.data.last_page,
                    per_page: res.data.per_page,
                    total: res.data.total,
                })
            })
            .catch(() => {
                setLoader(false);
            })
    };

    useEffect(() => {
        loadData();
        getDictionaryList()
            .then(res => {
                setFieldsList(res.data);
            })
            .finally(() => setLoader(false))
    }, [pagination, show, sort, tab, filterByValue]);

    useEffect(() => {
        if (isFirstRender) return;
        const handler = setTimeout(() => {
            setLoader(true);
            fetchExercises({
                search,
                page: 1,
                perPage: show,
                sort: sort[0],
                sortBy: sort[1],
                creator_ids: tab === 0 ? [] : [userId],
                ...filterParameters()
            })
                .then(res => {
                    setPagination(1);
                    setPage({
                        current_page: res.data.current_page,
                        last_page: res.data.last_page,
                        per_page: res.data.per_page,
                        total: res.data.total,
                    })
                    setData(res.data.exercises);
                    setLastPage(res.data.last_page);
                    setLoader(false);
                })
                .catch(() => {
                    setLoader(false);
                })
        }, 1000);
        return () => clearTimeout(handler);
    }, [search]);

    const handleSearch = e => {
        setSearch(e.target.value);
    };

    const handleDelete = () => {
        setLoader(true);
        deleteExercises(check.map(elem => elem.id))
            .then(() => {
                loadData();
                setDeleteModal(false);
                dispatch(AlertState.actions.alert({
                    text: check.length > 1 ? 'Exercise deleted successfully' : 'Exercises deleted successfully',
                    variant: true,
                }));
                setCheck([]);
            })
            .catch((err) => {
                setDeleteModal(false);
                let errText = catchErrorMessage(err);
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    const handleCloseModal = () => {
        setEditModal(false);
        setErrors(null);
    };

    const handleSaveExercise = (savedData) => {
        setLoader(true);
        editExercise(savedData.current, savedData.data)
            .then(() => {
                dispatch(AlertState.actions.alert({
                    text: 'Exercise is updated',
                    variant: true
                }))
                loadData();
                handleCloseModal();
            })
            .catch(err => {
                let errorMessage = catchErrorMessage(err);
                if (errorMessage === 'The variables field is required') {
                    errorMessage = 'Check at least one Unit of Measure'
                }

                if (errorMessage === 'The title has already been taken') {
                    setErrors({
                        title: 'The title has already been taken'
                    });
                }

                setEditModal(true);
                dispatch(AlertState.actions.alert({
                    text: errorMessage,
                    variant: false
                }))
            })
            .finally(() => setLoader(false))
    };

    const getOptions = () => {
        switch (column?.title) {
            case 'Body Segment':
                return fieldsList?.body_segments || []
            case 'Primary Muscle(s)':
                return fieldsList?.muscles || []
            case 'Equipment':
                return fieldsList?.equipment || []
            case 'Mechanics':
                return fieldsList?.mechanics || []
            case 'Force':
                return fieldsList?.forces || []
            default:
                return []
        }
    };

    return (
        <div className='exercises-list'>
            <Loader visible={loader} />

            {editModal && <EditExerciseModal
                modal={editModal}
                handleCloseModal={handleCloseModal}
                current={current}
                errors={errors}
                handleSaveExercise={handleSaveExercise}
            />}

            {viewModal && <ViewExerciseModal
                modal={viewModal}
                setModal={setViewModal}
                current={current}
            />}

            {deleteModal && <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                handleConfirm={handleDelete}
                title="Are you sure you want to delete selected exercises?"
                submitText="Yes"
                isCancelButton={true}
            />}

            <div className="exercises-list__header-list">
                <Tabs
                    value={tab}
                    onChange={(e, value) => setTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab label="ALL EXERCISES" style={{ fontSize: '12px', fontWeight: '700' }} />
                    <Tab label="MY EXERCISES" style={{ fontSize: '12px', fontWeight: '700' }} />
                </Tabs >

                <div className='btn-container'>
                    {permissions.find(elem => elem === 'create_exercises') && <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={() => history.push('/library/exercises/create')}
                    >
                        Create Exercise
                    </Button>}
                </div>
            </div>


            <div className='exercises-list__sort-container'>
                <div className='exercises-list__sort-buttons'>
                    <div className='exercises-list__sort-inputs'>
                        <MultipleSelectCheckmarks
                            options={attributes}
                            value={filter}
                            label="Display Attributes"
                            setValue={val => setFilter(val)}
                            width={200}
                        />

                        <div className='exercises-list__sort-input'>
                            <DropdownComponent
                                label="Select Column"
                                options={columns}
                                setFormData={setColumn}
                                value={column}
                                style={{ width: 200 }}
                                getOptionSelected={(option, value) => option.title === value.title}
                            />
                        </div>

                        <div className='exercises-list__sort-input'>
                            <DropdownComponent
                                value={filterByValue}
                                label="Select Column value"
                                options={getOptions()}
                                style={{ width: 200 }}
                                setFormData={setFilterByValue}
                                getOptionSelected={(option, value) => option.title === value.title}
                            />
                        </div>
                    </div>

                    <div className='exercises-list__buttons'>
                        {check.length === 1 && (
                            <>
                                <div className='exercises-list__button'>
                                    <Link to={`/exercise/${check[0].id}`} target="_blank">
                                        <Button
                                            variant="secondary"
                                        >Print</Button>
                                    </Link>
                                </div>
                                <div className='exercises-list__button'>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            if (check[0].is_editable) {
                                                setCurrent(check[0].id);
                                                setEditModal(true);
                                            } else {
                                                dispatch(AlertState.actions.alert({
                                                    text: 'You do not have permission to edit this exercise',
                                                    variant: false
                                                }))
                                            }
                                        }}
                                    >Edit</Button>
                                </div>
                                <Button
                                    variant="secondary"
                                    onClick={() => {
                                        history.push({
                                            pathname: `/library/exercises/create`,
                                            state: {
                                                exerciseId: check[0].id,
                                                isCloneForLibrary: true,
                                            }
                                        })
                                    }}
                                    style={{ margin: '0 0 0 10px' }}
                                >
                                    Duplicate
                                </Button>                                 
                            </>
                        )}

                        {check.length > 0 && (
                            <div className='exercises-list__button'>
                                <Button
                                    variant="secondary"
                                    onClick={() => setDeleteModal(true)}
                                >
                                    Delete
                                </Button>
                            </div>
                        )}
                    </div>
                </div>

                <div className='exercises-list__search'>
                    <TextField
                        variant="outlined"
                        label="Search"
                        value={search}
                        onChange={handleSearch}
                    />
                </div>
            </div>

            <Table
                data={data}
                check={check}
                setCheck={setCheck}
                sort={sort}
                setSort={setSort}
                filter={filter}
                setViewModal={setViewModal}
                setCurrent={setCurrent}
            />

            <Pagination
                show={show}
                setShow={setShow}
                page={page}
                setPage={setPage}
                pagination={pagination}
                setPagination={setPagination}
                lastPage={lastPage}
                data={data}
            />
        </div>
    )
};
