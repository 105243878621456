import React, { useState, useEffect } from "react";
import { TextField, Checkbox } from "@material-ui/core";
import Dropdown from "../../../../components/DropdownComponent";
import SimpleDropdown from "../../../../components/SimpleDropdown";
import { Button } from "react-bootstrap";
import "./create.scss";
import { useFormik } from "formik";
import {
  getDictionaryList,
  createExercise,
  fetchExercise,
} from "../../../../crud/library.crud";
import { useHistory, useLocation } from "react-router-dom";
import * as Yup from "yup";
import Loader from "../../../../components/Loader";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { useDispatch, useSelector } from "react-redux";
import catchErrorMessage from "../../../../helpers/errorCatcher";
import { validateForm } from "../validate";
import Cancel from "@material-ui/icons/Cancel";
import DropZone from "../../../../components/DropZone";
import * as media from "../../../../crud/media.crud";

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too short - should be at least 2 characters")
    .max(100, "Too long - should not exceed 100 characters")
    .required("Required"),
  body_segment: Yup.object().nullable(),
  equipment: Yup.object().nullable(),
  mechanic: Yup.object().nullable(),
  muscle: Yup.object().nullable(),
  instructions: Yup.string()
    .max(1000, "Too long - should not exceed 1000 characters")
    .nullable(),
  force: Yup.object().nullable(),
  video_source: Yup.string()
    .max(255, "Too long - should not exceed 255 characters")
    .nullable(),
  video_source_text: Yup.string()
    .max(200, "Too long - should not exceed 200 characters")
    .nullable(),
  video_source_preview: Yup.mixed().nullable(),
  video_type: Yup.string().nullable(),
  access: Yup.string().required("Required"),
});

const ImgComponent = ({ src, alt, className }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return url && <img src={url} alt={alt} className={className} />;
};

export const CreateExercise = ({
  refreshExercises,
  noRedirect = false,
  closeModal,
}) => {
  const subscription = useSelector(
    (state) => state?.user_info?.subscription?.plan?.name
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // vimeo
  // https://vimeo.com/692456322

  // youtube
  // https://www.youtube.com/watch?v=_Njq4iLHVlU

  const [video_type, set_video_type] = useState("");
  const [variables, setVariables] = useState([]);
  const [fieldsList, setFieldsList] = useState(null);
  const [loader, setLoader] = useState(false);
  const [is_video_share, set_is_video_share] = useState(false);
  const [photo, setPhoto] = useState();
  const [touchImg, setTouchImg] = useState(true);
  const exerciseId = location.state?.exerciseId;
  const isCloneForLibrary = location.state?.isCloneForLibrary || false;

  const handleSubmit = () => {
    setLoader(true);
    const data = {
      title: formik.values.title,
      body_segment_id: formik.values.body_segment?.id,
      equipment_id: formik.values.equipment?.id,
      force_id: formik.values.force?.id,
      mechanic_id: formik.values.mechanic?.id,
      muscle_id: formik.values.muscle?.id,
      is_video_share,
      access: formik.values.access,
      variables,
    };

    if (formik.values.video_source) {
      data.video_source = formik.values.video_source;
    }

    if (formik.values.video_source_text) {
      data.video_source_text = formik.values.video_source_text;
    }
    if (photo?.id) {
      data.file_id = photo?.id;
    }

    if (video_type) {
      data.video_type =
        video_type.charAt(0).toLowerCase() + video_type.slice(1);
    }

    if (formik.values.instructions) {
      data.instructions = formik.values.instructions;
    }

    createExercise(data)
      .then(() => {
        setLoader(false);
        dispatch(
          AlertState.actions.alert({
            text: "Exercise is created",
            variant: true,
          })
        );
        if (!noRedirect) {
          history.push("/library/exercises/list");
        }
        if (refreshExercises) refreshExercises();
        if (closeModal) closeModal();
      })
      .catch((err) => {
        let errorMessage = catchErrorMessage(err);
        if (errorMessage === "The variables field is required") {
          errorMessage = "Check at least one Unit of Measure";
        }
        dispatch(
          AlertState.actions.alert({
            text: errorMessage,
            variant: false,
          })
        );
        setLoader(false);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      body_segment: null,
      equipment: null,
      mechanic: null,
      muscle: null,
      force: null,
      instructions: "",
      video_source: "",
      video_source_text: "",
      video_source_preview: null,
      access: "myself",
    },
    validationSchema: ValidationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (exerciseId) {
      setLoader(true);
      fetchExercise(exerciseId)
        .then((res) => {
          const data = res.data.data;
          if (data.video_type) {
            data.video_type =
              res.data.data.video_type.charAt(0).toUpperCase() +
              res.data.data.video_type.slice(1);
          }
          formik.setValues({
            ...data,
            title: isCloneForLibrary ? 'Copy of ' + data.title : data.title,
          }, false);
          setVariables(res.data.data.variables);
          set_video_type(res.data.data.video_type);
          set_is_video_share(res.data.data.is_video_share);
          setLoader(false);
        })
        .catch(() => {
          setLoader(false);
        });
    }
  }, [exerciseId]);

  useEffect(() => {
    setLoader(true);
    getDictionaryList()
      .then((res) => {
        setFieldsList(res.data);
      })
      .finally(() => setLoader(false));
  }, []);

  const handleCheck = (item) => {
    let index = variables.findIndex((elem) => elem === item);
    if (index !== -1) {
      setVariables((prev) => {
        let arr = [...prev];
        arr.splice(index, 1);
        return arr;
      });
    } else {
      setVariables((prev) => [...prev, item]);
    }
  };

  const getVideoId = () => {
    if (
      formik.values?.video_source.indexOf(
        "https://www.youtube.com/watch?v="
      ) !== -1
    ) {
      return formik.values?.video_source.split(
        "https://www.youtube.com/watch?v="
      )[1];
    } else {
      return formik.values?.video_source.split("https://youtu.be/")[1];
    }
  };

  const handleChangeImage = (imageObj) => {
    formik.setFieldValue("video_source_preview", imageObj?.path);
    setPhoto(imageObj);
  };

  return (
    <form className="create-exercise" onSubmit={formik.handleSubmit} noValidate>
      <Loader visible={loader} />

      <div className="create-exercise__page-title">Create Exercise</div>

      <div className="create-exercise__page-body">
        <div className="create-exercise__page-block">
          <div className="create-exercise__textfield">
            <TextField
              name="title"
              variant="outlined"
              type="text"
              label="Exercise Name"
              inputProps={{ maxLength: 100 }}
              style={{ width: "100%" }}
              error={!!(formik.touched.title && formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="create-exercise__access-dropdown">
            <SimpleDropdown
              width={200}
              name="access"
              label="Access"
              value={
                formik.values.access === "myself"
                  ? "Myself"
                  : formik.values.access === "share"
                  ? "Shared with All Users"
                  : formik.values.access === "share_groups"
                  ? "Shared with Group"
                  : ""
              }
              onChange={(value) =>
                formik.handleChange({
                  target: {
                    value:
                      value === "Myself"
                        ? "myself"
                        : value === "Shared with All Users"
                        ? "share"
                        : value === "Shared with Group"
                        ? "share_groups"
                        : "",
                    name: "access",
                  },
                })
              }
              error={Boolean(formik.touched.access && formik.errors.access)}
              helperText={formik.touched.access && formik.errors.access}
              options={
                subscription === "Enterprise"
                  ? ["Myself", "Shared with All Users", "Shared with Group"]
                  : subscription === "Gym"
                  ? ["Myself", "Shared with All Users"]
                  : []
              }
              onBlur={formik.handleBlur}
              disableClearable={true}
            />
          </div>

          <div className="create-exercise__checkbox-title">
            Units of Measure
          </div>
          <div className="create-exercise__checkboxes-wrapper">
            <div className="create-exercise__checkboxes">
              <div className="create-exercise__checkbox">
                <Checkbox
                  color="primary"
                  checked={!!variables.find((elem) => elem === "reps")}
                  onChange={() => handleCheck("reps")}
                />
                <span>Reps</span>
              </div>

              <div className="create-exercise__checkbox">
                <Checkbox
                  color="primary"
                  checked={!!variables.find((elem) => elem === "weight")}
                  onChange={() => handleCheck("weight")}
                />
                <span>Weight</span>
              </div>

              <div className="create-exercise__checkbox">
                <Checkbox
                  color="primary"
                  checked={!!variables.find((elem) => elem === "distance")}
                  onChange={() => handleCheck("distance")}
                />
                <span>Distance</span>
              </div>

              <div className="create-exercise__checkbox">
                <Checkbox
                  color="primary"
                  checked={!!variables.find((elem) => elem === "time")}
                  onChange={() => handleCheck("time")}
                />
                <span>Time</span>
              </div>
            </div>
          </div>

          <div className="create-exercise__dropdown">
            <Dropdown
              label="Body Segment"
              options={fieldsList?.body_segments}
              width="100%"
              name="body_segment"
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: "body_segment" } })
              }
              value={formik.values.body_segment}
              error={
                !!(formik.touched.body_segment && formik.errors.body_segment)
              }
              helperText={
                formik.touched.body_segment && formik.errors.body_segment
              }
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="create-exercise__dropdown">
            <Dropdown
              label="Primary Muscle(s)"
              options={fieldsList?.muscles}
              width="100%"
              name="muscle"
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: "muscle" } })
              }
              value={formik.values.muscle}
              error={!!(formik.touched.muscle && formik.errors.muscle)}
              helperText={formik.touched.muscle && formik.errors.muscle}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="create-exercise__dropdown">
            <Dropdown
              label="Equipment"
              options={fieldsList?.equipment}
              width="100%"
              name="equipment"
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: "equipment" } })
              }
              value={formik.values.equipment}
              error={!!(formik.touched.equipment && formik.errors.equipment)}
              helperText={formik.touched.equipment && formik.errors.equipment}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="create-exercise__dropdown">
            <Dropdown
              label="Mechanics"
              options={fieldsList?.mechanics}
              width="100%"
              name="mechanic"
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: "mechanic" } })
              }
              value={formik.values.mechanic}
              error={!!(formik.touched.mechanic && formik.errors.mechanic)}
              helperText={formik.touched.mechanic && formik.errors.mechanic}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="create-exercise__dropdown">
            <Dropdown
              label="Force"
              options={fieldsList?.forces}
              width="100%"
              name="force"
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: "force" } })
              }
              value={formik.values.force}
              error={!!(formik.touched.force && formik.errors.force)}
              helperText={formik.touched.force && formik.errors.force}
              onBlur={formik.handleBlur}
            />
          </div>

          <div className="create-exercise__textarea">
            <TextField
              variant="outlined"
              type="text"
              onMouseDown={(e) => e.stopPropagation()}
              label="Instructions"
              name="instructions"
              inputProps={{ maxLength: 1000 }}
              style={{ width: "100%" }}
              multiline
              onChange={formik.handleChange}
              value={formik.values.instructions}
              error={
                !!(formik.touched.instructions && formik.errors.instructions)
              }
              helperText={
                formik.touched.instructions && formik.errors.instructions
              }
              onBlur={formik.handleBlur}
            />
          </div>
        </div>

        <div className="create-exercise__page-block create-exercise__page-block2">
          {formik.values.video_source && video_type ? (
            video_type === "Youtube" ? (
              <div className="create-exercise__video-container">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${getVideoId()}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : video_type === "Vimeo" ? (
              <div className="create-exercise__video-container">
                <iframe
                  title="Vimeo video player"
                  src={`https://player.vimeo.com/video/${
                    formik.values.video_source.split("https://vimeo.com/")[1]
                  }?h=5ca37da63f&color=000000&title=0&byline=0&portrait=0&badge=0`}
                  width="640"
                  height="360"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <></>
            )
          ) : (
            <div className="create-exercise__video-container-empty">
              Choose the video to see it here
            </div>
          )}

          <div className="create-exercise__dropdown">
            <SimpleDropdown
              label="Video Platform"
              options={["Youtube", "Vimeo"]}
              width="100%"
              onChange={(value) => set_video_type(value)}
              value={video_type}
              name="video_type"
              onBlur={formik.handleBlur}
              error={!!(formik.touched.video_type && formik.errors.video_type)}
              helperText={formik.touched.video_type && formik.errors.video_type}
            />
          </div>

          <div
            style={{ height: "40px" }}
            className="create-exercise__textfield"
          >
            <TextField
              label="Video URL"
              variant="outlined"
              type="text"
              style={{ width: "100%" }}
              name="video_source"
              inputProps={{ maxLength: 255 }}
              onChange={formik.handleChange}
              value={formik.values.video_source}
              error={
                !!(formik.touched.video_source && formik.errors.video_source)
              }
              helperText={
                formik.touched.video_source && formik.errors.video_source
              }
              disabled={!video_type}
            />
          </div>
          <div style={{ marginBottom: "6px" }}>
            Enter website URL for any exercises that is hosted on YouTube or
            Vimeo
          </div>
          <div className="create-exercise__textfield">
            <TextField
              label="Video Source"
              variant="outlined"
              type="text"
              style={{ width: "100%" }}
              name="video_source_text"
              inputProps={{ maxLength: 200 }}
              onChange={formik.handleChange}
              value={formik.values.video_source_text}
              error={
                !!(
                  formik.touched.video_source_text &&
                  formik.errors.video_source_text
                )
              }
              helperText={
                formik.touched.video_source_text &&
                formik.errors.video_source_text
              }
              disabled={!video_type}
            />
          </div>

          <div style={{ marginBottom: "15px" }} className="info-block">
            <div
              className="edit-exercise__checkbox-title"
              style={{ marginBottom: "15px" }}
            >
              Exercise Screenshot
            </div>
            <div>
              {formik.values.video_source_preview && touchImg ? (
                <div className="info-block__photo-preview">
                  <ImgComponent
                    src={formik.values.video_source_preview}
                    alt="profile"
                  />
                  <div
                    className="delete"
                    onClick={() => {
                      handleChangeImage(null);
                      setTouchImg(true);
                    }}
                  >
                    <Cancel />
                  </div>
                </div>
              ) : (
                <DropZone
                  submit={(e) => {
                    setTouchImg(false);
                    handleChangeImage(e);
                  }}
                  photo={formik.values.video_source_preview}
                  onDelete={() => {
                    handleChangeImage(null);
                    setTouchImg(false);
                  }}
                  disabled={!video_type}
                  Content={() => (
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#bdbdbd",
                        color: "#fafafa",
                        fontSize: "28px",
                        fontWeight: "700",
                      }}
                    >
                      {/* {values.first_name && values.first_name[0].toUpperCase()}
                      {values.last_name && values.last_name[0].toUpperCase()} */}
                    </div>
                  )}
                />
              )}
              <div style={{ color: "#fd397a" }}>
                {formik.errors.video_source_preview &&
                  formik.errors.video_source_preview}
              </div>
            </div>
          </div>

          <div
            style={{
              margin: "0 20px 0 -10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              color="primary"
              checked={is_video_share}
              onChange={() => set_is_video_share((prev) => !prev)}
            />
            <span>Share with TrainerMetrics community</span>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", marginTop: 30 }}>
        <Button
          variant="primary"
          className="btn-blue"
          onClick={(e) => {
            if (validateForm(formik, video_type, dispatch, AlertState)) {
              formik.handleSubmit();
            }
          }}
        >
          Create Exercise
        </Button>

        {closeModal && (
          <Button
            variant="primary"
            style={{ marginLeft: 15 }}
            onClick={closeModal}
          >
            Close Exercise
          </Button>
        )}
      </div>
    </form>
  );
};
