import React from "react";
import { Checkbox } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { DatePickerWithIcon } from "../../../Sales/Opportunities/components/OpportunitiesSummary/DatePickerWithIcon";
import { updateProfileProgram } from "../../../../../crud/profileProgram.crud";
import { useSelector } from "react-redux";
export const TableRow = ({ item, check, setCheck }) => {
  const history = useHistory();
  const location = useLocation();
  const profileId = location.pathname.split("/")[2];
  const role = useSelector((state) => state.auth.user.roles);

  const handleCheck = (item) => {
    const index = check.findIndex((elem) => elem.id === item.id);
    if (index !== -1) {
      setCheck((prev) => {
        let arr = [...prev];
        arr.splice(index, 1);
        return arr;
      });
    } else {
      setCheck((prev) => [
        ...prev,
        {
          id: item.id,
          workout_id: item.workout.id,
          is_editable: item.workout.is_editable,
          is_cloned: item.is_cloned || true,
          program: item.id || null,
        },
      ]);
    }
  };

  const handleRedirect = () => {
    history.push({
      pathname: `/profile/${profileId}/workouts/${item.workout.id}/program/${item.id}}/edit`,
      state: {
        assignmentId: item.id,
      },
    });
  };

  const handleUpdateScheduleDate = (value, item) => {
    try {
      updateProfileProgram(profileId, item.id, { assigned_date: value });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <tr key={item.id}>
      <td>
        <Checkbox
          color="primary"
          onChange={() => handleCheck(item)}
          checked={check.find((elem) => elem.id === item.id) !== undefined}
        />
      </td>
      <td className="left-content pointer" onClick={handleRedirect}>
        {item.workout?.title || "-"}
      </td>
      <td className="left-content pointer" onClick={handleRedirect}>
        {item.program?.title || "-"}
      </td>
      <td className="left-content pointer" onClick={handleRedirect}>
        {item.is_completed ? "Complete" : "Incomplete"}
      </td>
      <td className="left-content pointer">
        {
          <DatePickerWithIcon
            value={
              item.assigned_date
                ? moment(item.assigned_date).format("MM/DD/YYYY")
                : null
            }
            disabled={role === "client"}
            title={"Schedule Date"}
            onSave={(value) => handleUpdateScheduleDate(value, item)}
            useModalPortal={true}
            isValidationDisabled={true}
          />
        }
      </td>
      <td className="left-content pointer" onClick={handleRedirect}>
        {item.completed_by
          ? `${item.completed_by.first_name} ${item.completed_by.last_name}`
          : "-"}
      </td>
      <td className="left-content pointer" onClick={handleRedirect}>
        {item.completed_at
          ? moment(item.completed_at).format("MM/DD/YYYY")
          : "-"}
      </td>
    </tr>
  );
};
