import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, Button } from "react-bootstrap";
import { fetchWorkouts, addToProgram } from "../../../../crud/library.crud";
import Loader from "../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as AlertState from "../../../../../app/store/ducks/auth.duck";
import { Checkbox } from "@material-ui/core";
import { Tabs, Tab } from "@material-ui/core";
import catchErrorMessage from "../../../../helpers/errorCatcher";

export const AddWorkoutModal = ({ modal, setModal, loadData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [workouts, setWorkouts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loader, setLoader] = useState(false);
  const profile_id = location.pathname.split("/")[2];
  const [tab, setTab] = useState(0);
  const userId = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    setLoader(true);
    fetchWorkouts({
      page: 1,
      perPage: 500,
      creator_ids: tab === 0 ? [] : [userId],
    })
      .then((res) => {
        if (!res.data?.workouts) return;
        setWorkouts(res.data.workouts);
      })
      .finally(() => setLoader(false));
  }, [tab]);

  const handleClose = () => {
    setModal(false);
  };

  const handleSubmit = () => {
    if (!selected.length) {
      return dispatch(
        AlertState.actions.alert({
          text: "Select at least one Workout",
          variant: false,
        })
      );
    }

    setLoader(true);
    const data = {
      is_unattach_enabled: false,
      programs: [
        {
          id: null,
          workouts: selected.map((item) => ({ id: item.id })),
        },
      ],
    };
    addToProgram(profile_id, data)
      .then(() => {
        dispatch(
          AlertState.actions.alert({
            text: "Workouts added successfully",
            variant: true,
          })
        );
        setModal(false);
        loadData();
      })
      .catch((err) => {
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  const handleCheck = (workout) => {
    setSelected((prev) => {
      const arr = [...prev];
      const index = arr.findIndex((elem) => elem.id === workout.id);
      if (index === -1) {
        arr.push(workout);
      } else {
        arr.splice(index, 1);
      }
      return arr;
    });
  };

  const handleSelectAll = () => {
    setSelected((prev) => {
      if (prev.length === workouts.length) {
        return [];
      } else {
        return [...workouts];
      }
    });
  };

  return (
    <Modal show={modal !== false} onHide={() => setModal(false)}>
      <Loader visible={loader} />

      <StyledAddConfirmModal>
        <div className="close-button" onClick={() => setModal(false)}>
          ×
        </div>
        <Modal.Title>
          <h5 className="title">Add Workouts</h5>
        </Modal.Title>
        <Modal.Body>
          <Tabs
            value={tab}
            onChange={(e, value) => setTab(value)}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
            style={{ marginBottom: 20 }}
          >
            <Tab label={"ALL WORKOUTS"} />
            <Tab label={"MY WORKOUTS"} />
          </Tabs>

          <div className="options">
            {workouts.length > 0 && (
              <div className="option" onClick={handleSelectAll}>
                <Checkbox
                  color="primary"
                  checked={workouts.length === selected.length}
                />
                <div className="pointer">All Workouts</div>
              </div>
            )}

            {workouts.length > 0 ? (
              workouts.map((workout) => {
                return (
                  <div className="option" key={workout.id}>
                    <Checkbox
                      color="primary"
                      onChange={() => handleCheck(workout)}
                      checked={
                        !!selected.find((elem) => elem.id === workout.id)
                      }
                    />
                    <div
                      className="pointer"
                      onClick={() => handleCheck(workout)}
                    >
                      {workout.title}
                    </div>
                  </div>
                );
              })
            ) : (
              <div style={{ marginLeft: 10 }}>No workouts</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit} className="btn-blue">
            Save
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </StyledAddConfirmModal>
    </Modal>
  );
};

const StyledAddConfirmModal = styled.div`
  .modal-body {
    padding: 2.25rem;
  }

  .close-button {
    position: absolute;
    top: 9px;
    right: 20px;
    cursor: pointer;
    color: grey;
    font-size: 2.5em;
  }

  .title {
    margin: 20px 60px 0 30px;
    font-size: 1.4rem;
  }

  .options {
    max-height: 320px;
    overflow: auto;
  }

  .option {
    display: flex;
    align-items: center;

    &:first-child {
      font-weight: 700;
    }
  }

  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }
`;
