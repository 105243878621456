import React, { useState, useEffect } from "react";
import Dropdown from "../../../components/DropdownMultiselectv2";
import Dropdown2 from "../../../components/DropdownComponent";
import { Events } from "./Events/Events";
import { Tasks } from "./Tasks/Tasks";
import {
  fetchEventsStatistics,
  getTasksList,
  getTasksStatistics,
} from "../../../crud/dashboard.crud";
import {
  getGroupsByAccountId,
  getUsersV3,
  getOwnGroups,
} from "../../../crud/info.crud";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../app/store/ducks/auth.duck";
import catchErrorMessage from "../../../helpers/errorCatcher";
import { useIsMount } from "../../../hooks/useIsMount";
import Loader from "../../../components/Loader";
import moment from "moment";
import "./dashboard.scss";

export const Dashboard = () => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [pagination, setPagination] = useState(1);
  const [sort, setSort] = useState([]);
  const [type, setType] = useState(null);
  const [limit, setLimit] = useState(10);
  const [taskList, setTaskList] = useState([]);
  const [weeklyOpenedTaskList, setWeeklyOpenedTaskList] = useState([]);
  const [weeklyCompletedTaskList, setWeeklyCompletedTaskList] = useState([]);
  const [weeklyOpenedEventList, setWeeklyOpenedEventList] = useState([]);
  const [weeklyCompletedEventList, setWeeklyCompletedEventList] = useState([]);
  const [status, setStatus] = useState({ title: "Open", value: 0 });
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const accountId = useSelector((state) => state.appData.account.id);
  const role = useSelector((state) => state.auth.user.roles);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const userId = useSelector((state) => state.auth?.user?.id);
  const customColor = useSelector(
    (state) => state.builder.layoutConfig?.custom_color
  );
  const isFirstRender = useIsMount();
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  useEffect(() => {
    // Fetch groups
    if (!accountId || !role) return;
    if (role !== "admin" && role !== "super-admin" && role !== "supervisor") {
      setIsGroupLoading(false);
      return;
    }

    if (role === "supervisor") {
      getOwnGroups(userId)
        .then((res) => {
          if (res.data.list.length > 0) {
            setGroups(
              res.data.list.map((elem) => {
                return {
                  ...elem,
                  title: `${elem.name}`,
                };
              })
            );
            setSelectedGroup({
              ...res.data.list[0],
              title: `${res.data.list[0].name}`,
            });
          }
        })
        .finally(() => setIsGroupLoading(false));
      return;
    }

    getGroupsByAccountId(accountId)
      .then((res) => {
        if (res.data.list.length > 0) {
          setGroups(
            res.data.list.map((elem) => {
              return {
                ...elem,
                title: `${elem.name}`,
              };
            })
          );
          setSelectedGroup({
            ...res.data.list[0],
            title: `${res.data.list[0].name}`,
          });
        }
      })
      .finally(() => setIsGroupLoading(false));
  }, [role, accountId]);

  useEffect(() => {
    // Fetch users

    if (role && role !== "admin") {
      if (isFirstRender) return;
    }

    getUsersV3({
      group_id: selectedGroup?.id || null,
      limit: 500,
    })
      .then((res) => {
        const list = res.data?.list.map((elem) => ({
          ...elem,
          title: `${elem.first_name} ${elem.last_name}`,
        }));
        const sorted = list.sort((a, b) => {
          let first = a.title.toLowerCase();
          let second = b.title.toLowerCase();
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
        setUsers(sorted);
      })
      .catch((err) => {
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setIsUsersLoading(false));
  }, [selectedGroup, role, accountId, isFirstRender]);

  useEffect(() => {
    // Fetch tasks for header

    if (role === "staff") {
      if (isFirstRender || isGroupLoading) return;
    } else {
      if (isFirstRender || isGroupLoading || isUsersLoading) return;
    }

    if (selectedGroup && users.length < 1) {
      setWeeklyOpenedTaskList([]);
      setWeeklyCompletedTaskList([]);
      return;
    }

    let usersIds = [];

    if (selectedUsers?.length > 0) {
      usersIds = selectedUsers.map((elem) => elem.id);
    }

    if (selectedGroup && users.length > 0 && selectedUsers?.length < 1) {
      usersIds = users.map((elem) => elem.id);
    }

    const date1 = new Date(moment().utc());
    const date2 = new Date(moment().utc());
    let date_from =
      moment(date2.setDate(date2.getDate() - date2.getDay())).format(
        "YYYY-MM-DD"
      ) + " 00:00:00";
    date_from = moment(date_from)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let date_to =
      moment(date1.setDate(date1.getDate() + (6 - date1.getDay()))).format(
        "YYYY-MM-DD"
      ) + " 23:59:59";
    date_to = moment(date_to)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    if (accountId) {
      setLoader(true);
      getTasksStatistics(accountId, {
        page: pagination,
        limit,
        sort_field: sort[0],
        date_to,
        date_from,
        sort_direction: sort[1],
        "types[]": type,
        user_ids: usersIds,
        is_completed: status?.value,
      })
        .then((res) => {
          setWeeklyOpenedTaskList(res.data.opened);
          setWeeklyCompletedTaskList(res.data.closed);
        })
        .finally(() => setLoader(false));
    }
  }, [
    accountId,
    selectedUsers,
    selectedGroup,
    users,
    isGroupLoading,
    isUsersLoading,
    sort,
    pagination,
    limit,
    type,
    status,
  ]);

  useEffect(() => {
    // Fetch events for headerT
    if (!role) return;
    if (role === "staff") {
      if (isFirstRender) return;
    } else {
      if (isFirstRender || isUsersLoading || isGroupLoading) return;
    }

    if (selectedGroup && users.length < 1) {
      setWeeklyOpenedEventList([]);
      setWeeklyCompletedEventList([]);
      return;
    }

    let usersIds = [];

    if (selectedUsers?.length > 0) {
      usersIds = selectedUsers.map((elem) => elem.id);
    }

    if (selectedGroup && users.length > 0 && selectedUsers?.length < 1) {
      usersIds = users.map((elem) => elem.id);
    }

    const date1 = new Date();
    const date2 = new Date();
    let date_from =
      moment(date1.setDate(date1.getDate() - date1.getDay())).format(
        "YYYY-MM-DD"
      ) + " 00:00:00";
    date_from = moment(date_from)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let date_to =
      moment(date2.setDate(date2.getDate() + (6 - date2.getDay()))).format(
        "YYYY-MM-DD"
      ) + " 23:59:59";
    date_to = moment(date_to)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    if (accountId) {
      fetchEventsStatistics(accountId, {
        page: pagination,
        limit,
        sort_field: sort[0],
        date_to,
        date_from,
        sort_direction: sort[1],
        "types[]": type,
        user_ids: usersIds,
        is_completed: status?.value,
      }).then((res) => {
        setWeeklyOpenedEventList(res.data.opened);
        setWeeklyCompletedEventList(res.data.closed);
      });
    }
  }, [
    accountId,
    selectedUsers,
    selectedGroup,
    users,
    isUsersLoading,
    isGroupLoading,
    sort,
    pagination,
    limit,
    type,
    status,
    role,
  ]);

  useEffect(() => {
    // Fetch tasks for table
    if (isFirstRender || isGroupLoading || isUsersLoading) return;
    if (!accountId) return;

    setLoader(true);

    if (selectedGroup && users.length < 1) {
      setTaskList([]);
      setLoader(false);
      return;
    }

    let usersIds = [];

    if (selectedUsers?.length > 0) {
      usersIds = selectedUsers.map((elem) => elem.id);
    }

    if (selectedGroup && users.length > 0 && selectedUsers?.length < 1) {
      usersIds = users.map((elem) => elem.id);
    }

    const date1 = new Date();
    let date_from =
      moment(date1.setDate(date1.getDate() - date1.getDay())).format(
        "YYYY-MM-DD"
      ) + " 00:00:00";
    date_from = moment(date_from)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    let date_to =
      moment(date1.setDate(date1.getDate() + (6 - date1.getDay()))).format(
        "YYYY-MM-DD"
      ) + " 23:59:59";
    date_to = moment(date_to)
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    getTasksList(accountId, {
      page: pagination,
      limit,
      sort_field: sort[0],
      date_to,
      date_from,
      sort_direction: sort[1],
      "types[]": type,
      user_ids: usersIds,
      is_completed: status?.value,
    })
      .then((res) => {
        setTaskList(res.data.list);
        setPage({
          ...res.data.pagination,
          per_page: res.data.pagination.limit,
        });
        setLastPage(res.data.pagination.last_page);
        setLoader(false);
      })
      .finally(() => setLoader(false));
  }, [
    accountId,
    sort,
    pagination,
    limit,
    type,
    status,
    selectedUsers,
    selectedGroup,
    users,
    isGroupLoading,
    isUsersLoading,
  ]);

  const handleSelectGroup = (val) => {
    setIsUsersLoading(true);
    setSelectedGroup(val);
    setSelectedUsers([]);
  };

  const handleSelectUsers = (val) => {
    setSelectedUsers(val);
  };

  return (
    <div className="dashboard-page">
      <div className="first-block">
        <div className="header-wrapper">
          <h1 className="title">This Week</h1>
          {(role === "admin" ||
            role === "super-admin" ||
            role == "supervisor") &&
            plan !== "Gym" && (
              <div className="header-dropdown">
                <Dropdown2
                  options={groups}
                  value={selectedGroup}
                  label="Groups"
                  setFormData={handleSelectGroup}
                  getOptionSelected={(option, elem) => option.id === elem.id}
                  width={"200px"}
                />
              </div>
            )}

          {role !== "staff" && (
            <div className="header-dropdown">
              <Dropdown
                style={{ width: "200px" }}
                onChange={handleSelectUsers}
                value={selectedUsers}
                options={users}
                label="All Users"
              />
            </div>
          )}
        </div>
        <div className="header-section">
          <div className="header-block">
            <div
              className="header-block__number"
              style={{ color: customColor || "#ff722c" }}
            >
              {weeklyOpenedTaskList}
            </div>
            <div className="header-block__text">
              Open
              <br />
              Tasks
            </div>
          </div>
          <div className="header-block">
            <div
              className="header-block__number"
              style={{ color: customColor || "#ff722c" }}
            >
              {weeklyOpenedEventList}
            </div>
            <div className="header-block__text">
              Open
              <br />
              Events
            </div>
          </div>
          <div className="header-block">
            <div
              className="header-block__number"
              style={{ color: customColor || "#ff722c" }}
            >
              {weeklyCompletedTaskList}
            </div>
            <div className="header-block__text">
              Completed
              <br />
              Tasks
            </div>
          </div>
          <div className="header-block">
            <div
              className="header-block__number"
              style={{ color: customColor || "#ff722c" }}
            >
              {weeklyCompletedEventList}
            </div>
            <div className="header-block__text">
              Completed
              <br />
              Events
            </div>
          </div>
        </div>
        <Tasks
          setType={setType}
          data={taskList}
          limit={limit}
          setLimit={setLimit}
          page={page}
          setPage={setPage}
          pagination={pagination}
          setPagination={setPagination}
          lastPage={lastPage}
          setSort={setSort}
          sort={sort}
          setLoader={setLoader}
          status={status}
          setStatus={setStatus}
        />
      </div>
      <div className="second-block">
        <Events
          status={"isDashboard"}
          isFirstRender={isFirstRender}
          selectedUsers={selectedUsers}
          users={users}
          selectedGroup={selectedGroup}
          isGroupLoading={isGroupLoading}
          isUsersLoading={isUsersLoading}
        />
      </div>

      <Loader visible={loader} />
    </div>
  );
};
