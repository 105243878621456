import React from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Table } from "./Table/Table";
import Pagination from "../../../../components/Pagination";
import Dropdown from "../../../../components/SimpleDropdown";
import "./workouts.scss";

export const Workouts = ({
  handleSearch,
  data,
  search,
  check,
  setDeleteModal,
  setCheck,
  sort,
  setSort,
  show,
  setShow,
  page,
  setPage,
  pagination,
  setPagination,
  lastPage,
  handleChangeStatus,
}) => {
  const history = useHistory();
  const location = useLocation();
  const profileId = location.pathname.split("/")[2];

  const handleRedirect = () => {
    const url = check[0].program
      ? `/profile/${profileId}/workouts/${check[0].workout_id}/program/${check[0].program}/edit`
      : `/profile/${profileId}/workouts/${check[0].workout_id}/edit`;

    history.push({
      pathname: url,
      state: {
        assignmentId: check[0].id,
      },
    });
  };

  return (
    <div className="program-workouts">
      <div className="program-workouts__header" style={{ marginTop: 15 }}>
        <div className="d-flex">
          {check.length > 0 && (
            <div className="program-workouts__dropdown">
              <Dropdown
                label="Change Status"
                options={["Complete", "Incomplete"]}
                width="100%"
                name="body_segment"
                onChange={handleChangeStatus}
              />
            </div>
          )}

          {check.length === 1 && (
            <>
              <Link
                to={`/profile/${profileId}/workout/${check[0].id}/pdf`}
                target="_blank"
              >
                <Button variant="secondary" style={{ height: 40 }}>
                  Print
                </Button>
              </Link>

              <Button
                variant="secondary"
                onClick={handleRedirect}
                style={{ margin: "0 0 0 10px", height: 40 }}
              >
                Edit
              </Button>

              <Button
                variant="secondary"
                onClick={() => {
                  const url = check[0].program?.id
                    ? `/profile/${profileId}/workouts/${check[0].workout_id}/program/${check[0].program.id}/duplicate`
                    : `/profile/${profileId}/workouts/${check[0].workout_id}/duplicate`;
                  history.push({
                    pathname: url,
                    state: {
                      assignmentId: check[0].id,
                    },
                  });
                }}
                style={{ margin: "0 10px 0 10px", height: 40 }}
              >
                Duplicate
              </Button>
            </>
          )}

          {check.length > 0 && (
            <Button
              variant="secondary"
              onClick={() => setDeleteModal(true)}
              style={{ height: 40 }}
            >
              Delete
            </Button>
          )}
        </div>

        <TextField
          variant="outlined"
          label="Search"
          value={search}
          onChange={handleSearch}
        />
      </div>

      <Table
        data={data}
        check={check}
        setCheck={setCheck}
        sort={sort}
        setSort={setSort}
      />

      <Pagination
        show={show}
        setShow={setShow}
        page={page}
        setPage={setPage}
        pagination={pagination}
        setPagination={setPagination}
        lastPage={lastPage}
        data={data}
      />
    </div>
  );
};
