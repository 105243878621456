import React, { useState, useEffect, useRef } from "react";
import DataOutput from "../../components/DataOutput";
import UserInfo from "../../components/UserInfo";
import { Portlet, PortletBody } from "../../partials/content/Portlet";
import { Tabs, Tab } from "@material-ui/core";
import { Workouts } from "./ProgramTabs/Workouts/Workouts";
import { References } from "./ProgramTabs/References/References";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { AddToProgramModal } from "./ProgramTabs/Workouts/AddToProgramModal";
import { AddWorkoutModal } from "./ProgramTabs/Workouts/AddWorkoutModal";
import { useIsMount } from "../../hooks/useIsMount";
import { useLocation } from "react-router-dom";
import {
  fetchProfileProgram,
  changeProgramStatus,
} from "../../crud/library.crud";
import { deleteWorkoutsFromProfile } from "../../crud/profile.crud";
import { useDispatch, useSelector } from "react-redux";
import catchErrorMessage from "../../helpers/errorCatcher";
import * as AlertState from "../../../app/store/ducks/auth.duck";
import Loader from "../../components/Loader";
import ConfirmModal from "../../components/ConfirmationModal";
import "./style.scss";
import "./table.scss";

const Program = ({ match }) => {
  document.title = "TrainerMetrics - Program";
  const dispatch = useDispatch();
  const history = useHistory();
  const id = match.params.id;
  const [key, setKey] = useState(0);
  const [addToProgramModal, setAddToProgramModal] = useState(false);
  const isFirstRender = useIsMount();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(10);
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [check, setCheck] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sort, setSort] = useState(["created-at", "desc"]);
  const [createdDate, setCreatedDate] = useState(null);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);
  const [addWorkoutModal, setAddWorkoutModal] = useState(false);
  const profileId = location.pathname.split("/")[2];
  const user = useSelector((state) => state.auth?.user);

  const loadData = () => {
    setLoader(true);
    fetchProfileProgram(profileId, {
      search,
      page: pagination,
      perPage: show,
      sort: sort[0],
      sortBy: sort[1],
    })
      .then((res) => {
        setData(res.data.programs);
        setLastPage(res.data.last_page);
        setPage({
          current_page: res.data.current_page,
          last_page: res.data.last_page,
          per_page: res.data.per_page,
          total: res.data.total,
        });
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    loadData();
  }, [pagination, show, sort]);

  useEffect(() => {
    if (isFirstRender) return;
    const handler = setTimeout(() => {
      setLoader(true);
      fetchProfileProgram(profileId, {
        search,
        page: 1,
        perPage: show,
        sort: sort[0],
        sortBy: sort[1],
      })
        .then((res) => {
          setPagination(1);
          setPage({
            current_page: res.data.current_page,
            last_page: res.data.last_page,
            per_page: res.data.per_page,
            total: res.data.total,
          });
          setData(res.data.programs);
          setLastPage(res.data.last_page);
        })
        .finally(() => setLoader(false));
    }, 1000);
    return () => clearTimeout(handler);
  }, [search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleDelete = () => {
    setLoader(true);
    deleteWorkoutsFromProfile(
      profileId,
      check.map((elem) => elem.id)
    )
      .then(() => {
        setCheck([]);
        loadData();
        setDeleteModal(false);
        dispatch(
          AlertState.actions.alert({
            text:
              check.length > 1
                ? "Workout deleted successfully"
                : "Workouts deleted successfully",
            variant: true,
          })
        );
      })
      .catch((err) => {
        setDeleteModal(false);
        let errText = catchErrorMessage(err);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  const handleChangeStatus = (value) => {
    if (value === "Complete") {
      setLoader(true);
      changeProgramStatus(id, {
        workout_ids: check.map((elem) => elem.id),
        is_completed: true,
      })
        .then(() => {
          loadData();
          dispatch(
            AlertState.actions.alert({
              text: "Status changed successfully",
              variant: true,
            })
          );
        })
        .catch((err) => {
          let errText = catchErrorMessage(err);
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        })
        .finally(() => setLoader(false));
    } else if (value === "Incomplete") {
      setLoader(true);
      changeProgramStatus(id, {
        workout_ids: check.map((elem) => elem.id),
        is_completed: false,
      })
        .then(() => {
          loadData();
          dispatch(
            AlertState.actions.alert({
              text: "Status changed successfully",
              variant: true,
            })
          );
        })
        .catch((err) => {
          let errText = catchErrorMessage(err);
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        })
        .finally(() => setLoader(false));
    } else return;
  };

  const buttonRef = useRef();
  useEffect(() => {
    function clickOutside(e) {
      if (buttonRef && !buttonRef?.current?.contains(e.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener("mousedown", clickOutside);
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [buttonRef]);

  const buttonRef2 = useRef();
  useEffect(() => {
    function clickOutside(e) {
      if (buttonRef2 && !buttonRef2?.current?.contains(e.target)) {
        setDropdown2(false);
      }
    }

    document.addEventListener("mousedown", clickOutside);
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [buttonRef2]);

  return (
    <>
      <Loader visible={loader} />

      {addToProgramModal && (
        <AddToProgramModal
          modal={addToProgramModal}
          setModal={setAddToProgramModal}
          loadData={loadData}
        />
      )}

      {addWorkoutModal && (
        <AddWorkoutModal
          modal={addWorkoutModal}
          setModal={setAddWorkoutModal}
          loadData={loadData}
        />
      )}

      <ConfirmModal
        modal={deleteModal}
        setModal={setDeleteModal}
        handleConfirm={handleDelete}
        title="Are you sure you want to delete selected workouts?"
        submitText="Yes"
        isCancelButton={true}
      />

      <StyledProgram>
        <div
          className="row flex-md-row-reverse"
          style={{ marginBottom: "20px" }}
        >
          <DataOutput id={id} />
          <UserInfo id={id} />
        </div>

        <Portlet>
          <PortletBody>
            <div className="acord-none-mob">
              <Tabs
                value={key}
                onChange={(e, value) => setKey(value)}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant="scrollable"
                style={{ marginBottom: 20 }}
              >
                <Tab label={"WORKOUTS"} />
                <Tab label={"REFERENCES"} />
              </Tabs>

              {key === 0 && user.roles !== "client" && (
                <div className="btn-container">
                  <Button
                    variant="primary"
                    onClick={() => setAddToProgramModal(true)}
                  >
                    Add Program
                  </Button>

                  <div style={{ position: "relative", marginLeft: 20 }}>
                    <Button
                      variant="primary"
                      className="btn-blue"
                      onClick={() => setDropdown(true)}
                    >
                      Add/Create Workout
                    </Button>

                    <div
                      className="buttons-dropdown"
                      style={{
                        display: `${dropdown ? "" : "none"}`,
                        top: 0,
                        right: 0,
                        height: 60,
                      }}
                      ref={buttonRef}
                    >
                      <div className="buttons-dropdown__elem">
                        <span onClick={() => setAddWorkoutModal(true)}>
                          Add Workout
                        </span>
                      </div>
                      <div className="buttons-dropdown__elem">
                        <span
                          onClick={() =>
                            history.push(
                              `/profile/${profileId}/workouts/create`
                            )
                          }
                        >
                          Create Workout
                        </span>
                      </div>
                      <div
                        className="buttons-dropdown__arrow"
                        onClick={() => setDropdown(false)}
                      >
                        {">"}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {key === 0 && user.roles === "client" && (
                <div className="btn-container">
                  <Button
                    variant="primary"
                    className="btn-blue"
                    onClick={() =>
                      history.push(`/profile/${profileId}/workouts/create`)
                    }
                  >
                    Create Workout
                  </Button>
                </div>
              )}
            </div>

            {key === 0 && <div id="program-datepicker-portal"></div>}

            <div
              className="row acord-none-mob"
              style={{ alignItems: "flex-start" }}
            >
              {key === 0 && (
                <Workouts
                  handleSearch={handleSearch}
                  data={data}
                  createdDate={createdDate}
                  search={search}
                  check={check}
                  setDeleteModal={setDeleteModal}
                  setCheck={setCheck}
                  sort={sort}
                  setSort={setSort}
                  show={show}
                  setShow={setShow}
                  page={page}
                  setPage={setPage}
                  pagination={pagination}
                  setPagination={setPagination}
                  lastPage={lastPage}
                  handleChangeStatus={handleChangeStatus}
                />
              )}

              {key === 1 && <References id={id} />}
            </div>

            <div className="row acord-none-dt">
              <div
                className="acord-title"
                onClick={() => setKey(key === 0 ? null : 0)}
              >
                WORKOUTS
              </div>

              {key === 0 && (
                <>
                  {user.roles !== "client" && (
                    <div className="btn-container">
                      <Button
                        variant="primary"
                        onClick={() => setAddToProgramModal(true)}
                      >
                        Add Program
                      </Button>

                      <div style={{ position: "relative", marginLeft: 20 }}>
                        <Button
                          variant="primary"
                          className="btn-blue"
                          onClick={() => setDropdown2(true)}
                        >
                          Add/Create Workout
                        </Button>

                        <div
                          className="buttons-dropdown"
                          style={{
                            display: `${dropdown2 ? "" : "none"}`,
                            top: 0,
                            right: 0,
                            height: 60,
                          }}
                          ref={buttonRef2}
                        >
                          <div className="buttons-dropdown__elem">
                            <span onClick={() => setAddWorkoutModal(true)}>
                              Add Workout
                            </span>
                          </div>
                          <div className="buttons-dropdown__elem">
                            <span
                              onClick={() =>
                                history.push(
                                  `/profile/${profileId}/workouts/create`
                                )
                              }
                            >
                              Create Workout
                            </span>
                          </div>
                          <div
                            className="buttons-dropdown__arrow"
                            onClick={() => setDropdown(false)}
                          >
                            {">"}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {user.roles === "client" && (
                    <div className="btn-container">
                      <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={() =>
                          history.push(`/profile/${profileId}/workouts/create`)
                        }
                      >
                        Create Workout
                      </Button>
                    </div>
                  )}

                  <Workouts
                    handleSearch={handleSearch}
                    data={data}
                    createdDate={createdDate}
                    setCreatedDate={setCreatedDate}
                    search={search}
                    check={check}
                    setDeleteModal={setDeleteModal}
                    setCheck={setCheck}
                    sort={sort}
                    setSort={setSort}
                    show={show}
                    setShow={setShow}
                    page={page}
                    setPage={setPage}
                    pagination={pagination}
                    setPagination={setPagination}
                    lastPage={lastPage}
                    handleChangeStatus={handleChangeStatus}
                  />
                </>
              )}
            </div>

            <div className="row acord-none-dt">
              <div
                className="acord-title"
                onClick={() => setKey(key === 1 ? null : 1)}
              >
                REFERENCES
              </div>

              {key === 1 && <References id={id} />}
            </div>
          </PortletBody>
        </Portlet>
      </StyledProgram>
    </>
  );
};

export default Program;

const StyledProgram = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn-container {
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
      margin-top: 20px;
      margin-left: 10px;
    }
  }
`;
