import React, { useEffect, useState } from "react";
import MenuSection from "./MenuSection";
import MenuItemSeparator from "./MenuItemSeparator";
import MenuItem from "./MenuItem";
import { getOwnGroups } from "../../../app/crud/info.crud";
import { useSelector } from "react-redux";

const roles = ["supervisor", "admin", "super-admin"];
const accessTo = ["ABC Fitness"];

export const MenuList = ({ currentUrl, menuConfig, layoutConfig }) => {
  const [items, setItems] = useState(menuConfig?.aside.items);
  const crm = useSelector((state) =>
    state.user_info.CRM_list.find((elem) => elem.is_activated === true)
  );

  const tasksCount = useSelector((state) => state.appData.tasksCount);

  const isSalesEnabled = useSelector((state) =>
    state.user_info.CRM_list.find(
      (elem) => accessTo.includes(elem.title) && elem.is_sales_module_enabled
    )
  );

  const subscription = useSelector((state) => state?.user_info?.subscription);
  const userId = useSelector((state) => state.auth?.user?.id);
  const role = useSelector((state) => state.auth.user.roles);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);

  const hideElements = (items = []) => {
    if (!(roles.includes(role) && crm && isSalesEnabled)) {
      return items.filter((elem) => elem.title !== "Sales");
    }

    return items;
  };

  useEffect(() => {
    if (!menuConfig?.aside?.items?.length) return;

    if ((role === "supervisor" || plan) && userId) {
      getOwnGroups(userId).then((res) => {
        if (role === "staff") {
          const arr = menuConfig?.aside.items.filter(
            (elem) => elem.title !== "Users" && elem.title !== "Groups"
          );
          return setItems(arr);
        }

        if (role === "supervisor" && res.data.list.length <= 1) {
          const arr = menuConfig?.aside.items.filter(
            (elem) => elem.title !== "Groups"
          );
          return setItems(arr);
        }

        if (plan === "Gym") {
          const arr = menuConfig?.aside.items.filter(
            (elem) => elem.title !== "Groups"
          );
          return setItems(arr);
        }

        if (plan === "Trainer" || plan === "Free") {
          const arr = menuConfig?.aside.items.filter(
            (elem) => elem.title !== "Groups" && elem.title !== "Users"
          );
          return setItems(arr);
        }

        setItems(menuConfig?.aside.items);
      });
    }
  }, [userId, role, menuConfig?.aside.items, plan]);

  if (!menuConfig) return <></>;

  return hideElements(items)?.map((child, index) => {
    if (child.title === "CRM" && !crm) {
      return null;
    }

    if (child.title === "CRM" && subscription?.plan?.name !== "Enterprise") {
      child.submenu = child.submenu?.filter((elem) => elem.title !== "Group");
    } else if (child.title === "CRM" && crm.title === "Motionsoft") {
      child.submenu = child.submenu?.filter((elem) => elem.title !== "Event");
    } else if (child.title === "CRM") {
      child.submenu = [
        {
          title: "Connect",
          page: "account/crm/connect",
        },
        {
          title: "Group",
          page: "account/crm/group",
        },
        {
          title: "User",
          page: "account/crm/user",
        },
        {
          title: "Profile",
          page: "account/crm/profile",
        },
        {
          title: "Event",
          page: "account/crm/event",
        },
      ];
    }

    return (
      <React.Fragment key={`menuList${index}`}>
        {child.section && <MenuSection item={child} />}
        {child.separator && <MenuItemSeparator item={child} />}
        {child.title && (
          <MenuItem
            item={child}
            currentUrl={currentUrl}
            layoutConfig={layoutConfig}
            tasksCount={tasksCount}
            // ktoffConvas={ktoffConvas}
          />
        )}
      </React.Fragment>
    );
  });
};
