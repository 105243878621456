import React, { useState } from "react";

import DatePicker from "../../../../../../components/DatePicker";

import styled from "styled-components";

import * as yup from "yup";
import moment from "moment";

const schema = yup
  .string()
  .test("date-format", "Date must be in MM/DD/YYYY format", (value) => {
    return moment(value, "MM/DD/YYYY", true).isValid();
  })
  .required("Date is required");

export const DatePickerWithIcon = ({
  value,
  onSave,
  useModalPortal = false,
  disabled = false,
  isValidationDisabled = false,
}) => {
  const [data, setData] = useState(value);

  const handleChange = async (data) => {
    setData(data);

    try {
      await schema.validate(data);

      onSave(data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper className={`${disabled ? "disabled" : ""}`}>
      <DatePicker
        format={"MM/DD/YYYY"}
        value={data}
        onChange={handleChange}
        future={true}
        past={true}
        error={false}
        position={"bottom"}
        disabled={disabled}
        withIcon={true}
        useModalPortal={useModalPortal}
        isValidationDisabled={isValidationDisabled}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  gap: 6px;
  cursor: pointer;
  &.disabled {
    cursor: unset;
  }
  & .picker {
    background-color: transparent !important;
    border: none !important;
    margin: 0;
    padding: 0;
    height: 24px;
    & .x-button {
      display: none;
    }
    & .input-mask {
      color: rgb(100, 108, 154);
    }
  }
`;
