import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Loader from "../../../../components/Loader";
import { TextField } from "@material-ui/core";
import DatePicker from "../../../../components/DatePicker";
import DropdownComponent from "../../../../components/DropdownComponent";
import SimpleDropdown from "../../../../components/SimpleDropdown";
import Dropdown from "../../../../components/DropdownMultiselectv2";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { getUsersV2 } from "../../../../crud/info.crud";
import { getProfileList } from "../../../../crud/profile.crud";
import catchErrorMessage from "../../../../helpers/errorCatcher";
import { createTask } from "../../../../crud/dashboard.crud";
import { getSingleTask } from "../../../../crud/dashboard.crud";
import { editTask } from "../../../../crud/dashboard.crud";
import * as Yup from "yup";
import moment from "moment";
import { validate } from "./validate";
import "./task-modal.scss";
import { useLocation } from "react-router-dom";

const timeArray = [
  "12:00 am",
  "12:15 am",
  "12:30 am",
  "12:45 am",
  "01:00 am",
  "01:15 am",
  "01:30 am",
  "01:45 am",
  "02:00 am",
  "02:15 am",
  "02:30 am",
  "02:45 am",
  "03:00 am",
  "03:15 am",
  "03:30 am",
  "03:45 am",
  "04:00 am",
  "04:15 am",
  "04:30 am",
  "04:45 am",
  "05:00 am",
  "05:15 am",
  "05:30 am",
  "05:45 am",
  "06:00 am",
  "06:15 am",
  "06:30 am",
  "06:45 am",
  "07:00 am",
  "07:15 am",
  "07:30 am",
  "07:45 am",
  "08:00 am",
  "08:15 am",
  "08:30 am",
  "08:45 am",
  "09:00 am",
  "09:15 am",
  "09:30 am",
  "09:45 am",
  "10:00 am",
  "10:15 am",
  "10:30 am",
  "10:45 am",
  "11:00 am",
  "11:15 am",
  "11:30 am",
  "11:45 am",
  "12:00 pm",
  "12:15 pm",
  "12:30 pm",
  "12:45 pm",
  "01:00 pm",
  "01:15 pm",
  "01:30 pm",
  "01:45 pm",
  "02:00 pm",
  "02:15 pm",
  "02:30 pm",
  "02:45 pm",
  "03:00 pm",
  "03:15 pm",
  "03:30 pm",
  "03:45 pm",
  "04:00 pm",
  "04:15 pm",
  "04:30 pm",
  "04:45 pm",
  "05:00 pm",
  "05:15 pm",
  "05:30 pm",
  "05:45 pm",
  "06:00 pm",
  "06:15 pm",
  "06:30 pm",
  "06:45 pm",
  "07:00 pm",
  "07:15 pm",
  "07:30 pm",
  "07:45 pm",
  "08:00 pm",
  "08:15 pm",
  "08:30 pm",
  "08:45 pm",
  "09:00 pm",
  "09:15 pm",
  "09:30 pm",
  "09:45 pm",
  "10:00 pm",
  "10:15 pm",
  "10:30 pm",
  "10:45 pm",
  "11:00 pm",
  "11:15 pm",
  "11:30 pm",
  "11:45 pm",
];

const ValidationSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too short - should be at least 2 characters")
    .max(100, "Too long - should not exceed 100 characters")
    .required("Required"),
  date: Yup.mixed()
    .required("Required")
    .test("Invalid date", "Invalid date", function(value) {
      if (typeof value === "string" && value.trim().length < 10) return false;
      return true;
    })
    .test("Required", "Required", function(value) {
      if (typeof value === "string" && value === "  /  /    ") return false;
      return true;
    }),
  time: Yup.string().required("Required"),
  priority: Yup.object()
    .required("Required")
    .nullable(),
  profiles: Yup.array()
    .required("Required")
    .min(1, "Required"),
  users: Yup.array()
    .required("Required")
    .min(1, "Required"),
  description: Yup.string()
    .min(2, "Too short - should be at least 2 characters")
    .max(1000, "Too long - should not exceed 1000 characters"),
});

const initValues = {
  title: "",
  date: moment().format("MM/DD/YYYY"),
  time: "12:00 am",
  priority: "",
  users: [],
  profiles: [],
  description: "",
};

const priorityList = [
  {
    title: "Urgent",
    value: "urgent",
  },
  {
    title: "High",
    value: "high",
  },
  {
    title: "Normal",
    value: "normal",
  },
  {
    title: "Low",
    value: "low",
  },
];

export const TaskModal = ({
  modal,
  setModal,
  loadTasks,
  isEdit,
  check,
  setIsEdit,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [userList, setUserList] = useState([]);
  const [profileList, setProfileList] = useState([]);
  const accountId = useSelector((state) => state.appData.account.id);
  const role = useSelector((state) => state.auth.user.roles);
  const location = useLocation();
  const profileId = location.pathname.split("/")[2];
  const userId = useSelector((state) => state.auth.user.id);

  useEffect(() => {
    if (isEdit) {
      setLoader(true);
      getSingleTask(accountId, check[0])
        .then((res) => {
          const data = res.data.single;
          const date = moment(data.perform_at).format("MM/DD/YYYY");
          const time = moment(data.perform_at).format("HH:mm:ss");
          const priority = priorityList.find(
            (elem) => elem.value === data.priority
          );
          const users = data.users.map((elem) => ({
            ...elem,
            title: elem.first_name + " " + elem.last_name,
          }));
          const profiles = data.profiles.map((elem) => ({
            title: elem.first_name + " " + elem.last_name,
            ...elem,
            profile_id: elem.id,
          }));
          if (!data.description) delete data.description;
          formik.setValues({
            ...data,
            date,
            time,
            priority,
            users,
            profiles,
          });
        })
        .finally(setLoader(false));
    }
  }, [isEdit]);

  const handleSubmit = () => {
    setLoader(true);
    const data = {
      ...formik.values,
      type: "general",
      perform_at: moment(`${formik.values.date} ${formik.values.time}`)
        .utc()
        .format("YYYY/MM/DD HH:mm:ss"),
      priority: formik.values.priority.value,
      users: formik.values.users.map((elem) => elem.id),
      profiles: formik.values.profiles.map((elem) => elem.profile_id),
    };
    if (!data.description) delete data.description;
    delete data.date;
    delete data.time;

    if (isEdit) {
      editTask(accountId, check[0], data)
        .then(() => {
          handleClose();
          loadTasks();
          dispatch(
            AlertState.actions.alert({
              text: "Task is edited",
              variant: true,
            })
          );
        })
        .catch((err) => {
          dispatch(
            AlertState.actions.alert({
              text: catchErrorMessage(err),
              variant: false,
            })
          );
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      createTask(accountId, data)
        .then(() => {
          handleClose();
          loadTasks();
          dispatch(
            AlertState.actions.alert({
              text: "Task is created",
              variant: true,
            })
          );
        })
        .catch((err) => {
          dispatch(
            AlertState.actions.alert({
              text: catchErrorMessage(err),
              variant: false,
            })
          );
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initValues,
    validationSchema: ValidationSchema,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (!isNaN(+profileId) && profileList.length) {
      formik.setFieldValue("profiles", [
        profileList.find((elem) => +elem.profile_id === +profileId),
      ]);
    }
  }, [profileId, profileList]);

  useEffect(() => {
    if (userId && role === "staff" && userList.length) {
      formik.setFieldValue("users", [
        userList.find((elem) => +elem.id === +userId),
      ]);
    }
  }, [role, userId, userList]);

  useEffect(() => {
    if (modal) {
      getUsersV2()
        .then((res) => {
          const list = res.data?.list.map((elem) => ({
            ...elem,
            title: `${elem.first_name} ${elem.last_name}`,
          }));
          const sorted = list.sort((a, b) => {
            let first = a.title.toLowerCase();
            let second = b.title.toLowerCase();
            if (first < second) {
              return -1;
            }
            if (first > second) {
              return 1;
            }
            return 0;
          });
          sorted.unshift({
            id: "select_all",
            title: "(Select All)",
          });
          setUserList(sorted);
        })
        .catch((err) => {
          let errText = catchErrorMessage(err);
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        });

      getProfileList().then((res) => {
        const newArr = [];
        res.data.data.profiles.forEach((elem) => {
          const profile = {
            ...elem,
            title: `${elem.first_name} ${elem.last_name}`,
          };
          newArr.push(profile);
        });

        const arr = newArr.sort((a, b) => {
          let nameA = a.title.toLowerCase();
          let nameB = b.title.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setProfileList(arr);
      });
    }
  }, [modal]);

  const handleClose = () => {
    setIsEdit(false);
    setModal(false);
    formik.resetForm();
  };

  const onDateChange = (value, name) => {
    let val = value;
    if (value === "  /  /    ") val = null;
    formik.handleChange({ target: { value: val, name } });
  };

  const getTimeArray = () => {
    if (formik.values.end_time) {
      const index = timeArray.findIndex(
        (elem) => elem === formik.values.end_time
      );
      let arr = [...timeArray];
      arr.splice(index, arr.length - index);
      return arr;
    }
    return timeArray;
  };

  return (
    <Modal show={modal !== false} onHide={handleClose}>
      <div className="task-modal">
        <Loader visible={loader} />
        <div className="close-button" onClick={handleClose}>
          ×
        </div>
        <Modal.Title>
          <h5 className="title">{isEdit ? "Edit Task" : "Create Task"}</h5>
        </Modal.Title>
        <Modal.Body>
          <div className="row">
            <TextField
              name="title"
              style={{ width: "100%" }}
              variant="outlined"
              type="text"
              label="Task Name"
              inputProps={{
                maxLength: 100,
              }}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!(formik.touched.title && formik.errors.title)}
              helperText={!!formik.touched.title && formik.errors.title}
            />
          </div>
          <div className="row">
            <div className="row__elem">
              <DatePicker
                touchPicker={formik.errors.date && true}
                name="date"
                label="Task Date"
                future={true}
                format={"MM/DD/YYYY"}
                value={formik.values.date}
                onChange={(value) => onDateChange(value, "date")}
                onBlur={() => formik.handleBlur({ target: { name: "date" } })}
                error={formik.touched.date && !!formik.errors.date}
                helperText={formik.touched.date && formik.errors.date}
              />
            </div>
            <div className="row__elem">
              <SimpleDropdown
                label="Task Time"
                width="100%"
                options={getTimeArray()}
                value={formik.values.time}
                onChange={(value) =>
                  formik.handleChange({ target: { value, name: "time" } })
                }
                error={formik.touched.time && !!formik.errors.time}
                helperText={formik.touched.time && formik.errors.time}
                onBlur={() => formik.handleBlur({ target: { name: "time" } })}
              />
            </div>
          </div>

          <div className="row">
            <DropdownComponent
              name="priority"
              width={"100%"}
              label="Priority"
              options={priorityList}
              value={formik.values.priority}
              setFormData={(value) =>
                formik.handleChange({ target: { value, name: "priority" } })
              }
              onBlur={formik.handleBlur}
              error={!!(formik.touched.priority && formik.errors.priority)}
              helperText={!!formik.touched.priority && formik.errors.priority}
              getOptionSelected={(option, value) =>
                option.title === value.title
              }
            />
          </div>

          {role !== "staff" && (
            <div className="row" style={{ position: "relative", zIndex: "1" }}>
              <Dropdown
                name="users"
                style={{ width: "100%" }}
                label="User(s)"
                onChange={(value) =>
                  formik.handleChange({ target: { value, name: "users" } })
                }
                onChange={(value) => {
                  if (value.some((item) => item.id === "select_all")) {
                    formik.setFieldValue(
                      "users",
                      userList.filter((user) => user.id !== "select_all")
                    );
                  } else {
                    formik.handleChange({ target: { value, name: "users" } });
                  }
                  document.activeElement.blur();
                }}
                value={formik.values.users}
                options={userList}
                error={formik.touched.users && !!formik.errors.users}
                onBlur={formik.handleBlur}
                helperText={formik.touched.users && formik.errors.users}
                getOptionSelected={(option, value) => option.id === value.id}
              />
            </div>
          )}

          <div className="row" style={{ position: "relative", zIndex: "0" }}>
            <Dropdown
              name="profiles"
              style={{ width: "100%" }}
              label="Profile(s)"
              options={profileList}
              value={formik.values.profiles}
              onChange={(value) =>
                formik.handleChange({ target: { value, name: "profiles" } })
              }
              onBlur={formik.handleBlur}
              error={!!(formik.touched.profiles && formik.errors.profiles)}
              helperText={!!formik.touched.profiles && formik.errors.profiles}
              getOptionSelected={(option, value) =>
                option.profile_id === value.profile_id
              }
              disabled={!isNaN(+profileId) ? true : false}
            />
          </div>

          <div className="row" style={{ position: "relative", zIndex: "0" }}>
            <TextField
              multiline
              rows="2"
              name="description"
              style={{ width: "100%" }}
              variant="outlined"
              type="text"
              label="Task Note"
              inputProps={{
                maxLength: 100,
              }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                !!(formik.touched.description && formik.errors.description)
              }
              helperText={
                !!formik.touched.description && formik.errors.description
              }
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="btn-blue"
            onClick={() => {
              if (validate(formik)) {
                formik.handleSubmit();
              }
            }}
          >
            {isEdit ? "Save" : "Create"}
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
